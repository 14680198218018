@import '../../utilities/lib.less';
.budget-overview{
	.werbeformbudgets, .kategorienbudgets, .kategoriewerbeformbudgets{
		margin-left: 10px;
		margin-bottom: 10px;
		.diagram-container{
			background: repeating-linear-gradient(
				90deg, @MediumGrey, @MediumGrey 1px, transparent 0px, transparent 10%
			);
			border-right: solid 1px @MediumGrey;
			.diagram-wrapper{
				background: @PrimaryCiColor
			}
		}
	}
}