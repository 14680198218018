@import '../../utilities/lib.less';
#editMask.newsletter .bas-formSection {
	.item.horizontal.ym-clearfix {
		display: table;
		width: 100%;
		&.contains-input {
			width: 100%;
		}
	
		.item.vertical .itemBox {
			display: block;
			margin-bottom: 0;
		}
	
		.itemBox {
			display: table-cell;
			float: none;
			vertical-align: top;
			&.next-element-is-tooltip {
				width: 100%;
			}
			input[type=text], select , textarea, .inlineckeditor {
				width: 100% ! important;
			}
	
			input {
				&.cols1 {
					width: 70px ! important;
				}
	
				&.cols12 {
					width: @ColsInput12 ! important;
				}
	
				&.cols_tele_1 {
					width: @ColsInputTele1 ! important;
				}
	
				&.cols_tele_2 {
					width: @ColsInputTele1 ! important;
				}
	
				&.cols_tele_3 {
					width: 187px ! important;
				}
	
				&.cols_tele_4 {
					width: @ColsInputTele1 ! important;
				}
	
				.bas-inputCheck , .bas-inputCheck .lockcheckbox {
					padding-right: 0;
					margin-right: 0;
				}
	
				.bas-inputCheck .lockcheckbox {
					background-position: left top;
				}
			}
			
			.bas-inputCheck .lockcheckbox {
				display: inline-block;
			}
	
			select.tyre-labelling-select-filter {
				width: 158px ! important;
			}
		}
	
		sup {
			width: 12px;
			display: inline-table;
		}
	
		div.radio {
			padding-right: 20px;
			width: auto;
			position: relative;
			top: 0.3em;
			height: 18px;
		}
	
		div.cols3 , div.cols3 input[type=text] , div.cols3 select , div.cols3 textarea, div.cols3 .inlineckeditor {
			width: 100%;
		}
	
		.bas-formSection .footnoteIndex {
			+ .itemBox .bas-inputText {
				width: 380px;
			}
	
			~ .itemBox a {
				margin-left: @GuttingDefault;
				padding: 0;
			}
		}
	
		.ym-grid select.co2-select-filter {
			width: 213px ! important;
		}
	
		select.co2selection + div.selectBox {
			margin-bottom: @GuttingDefault;
		}
	
		.cke {
			width: 100% ! important;
		}
	
	}
	div.box.error.small {
		margin-left: 10px;
	}
	div.bas-lockCheckbox {
		display: inline-block;
		margin-top: 2px;
		float: none;
	}
}

div.newsletter #editMaskContainer .bas-formSection .item.horizontal.ym-clearfix {
	.itemBox {
		input {
			&.cols12 {
				width: 262px ! important;
			}

			&.cols_tele_1 {
				width: @ColsInputTele1 ! important;
			}

			&.cols_tele_2 {
				width: @ColsInputTele1 ! important;
			}

			&.cols_tele_3 {
				width: 162px ! important;
			}

			&.cols_tele_4 {
				width: @ColsInputTele1 ! important;
			}
		}
	}
}

div.newsletter #editMaskContainer .layoutGrid div.ym-g50 {
    min-height: 30px;
}

.footnoteIndex sup {
	top: 0;
	vertical-align: top;
	line-height: 10px;
}

