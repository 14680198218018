@import '../../utilities/lib.less';

.defaultLink.icon {
    &:hover {
        border-bottom: none;
    }

    &.text {
        .iconContainer {
            float: left;
        }

        >span {
            float: left;
            line-height: 18px;
        }

        &.inactive {

            &:hover span,
            span {
                border-bottom: 1px solid transparent;
            }
        }

        &.inline {
            margin-top: 2px;
            margin-left: 5px;
            line-height: 14px;
        }
    }
}

.defaultLink,
.dummyLink,
header .defaultLink,
.linkGroup a {

    &.paginationFastJump,
    &.paginationPageJump {

        &,
        &:hover {
            border-bottom: none;
        }

        >span {
            color: @ButtonColor;
            border-bottom: 1px solid transparent;
        }

        &:hover>span {
            color: @HoverColor;
            border-bottom: 1px solid @HoverColor;
        }
    }
}

.noIcon.defaultLink {
    background: none;
    padding: 0;
}

#adornment {

    a,
    >span,
    .user {
        padding: 6px 6px 6px 15px;
    }

    a.defaultLink {
        background: @PrimaryBackgroundColor;
        border-radius: 0;
        height: 20px;
        display: block;
        width: auto;
        border: none;

        >span {
            border-bottom: 1px solid transparent;
            color: @PrimaryFontColor;
        }

        &:hover>span {
            color: @HoverColor;
            .transition (~"background-image 200ms ease-out");
            text-decoration: none;
            border-bottom: 1px solid @HoverColor;
        }
    }

    .user {

        .userName,
        .userId {
            font-weight: bold;
        }

        .userId {
            word-break: break-all;
        }
    }

    >span {
        display: inline-block;
        font-weight: bold;

        &>span {
            padding: 0;
        }
    }
}

#copygridfamily,
#editlocalizedtemplate,
.defaultLink.icon.text.toTemplate,
#editcms {
    height: 18px;

    &:hover span {
        color: @HighlightColor;
        border-bottom: 1px solid @HighlightColor;
    }
}

#nav .ym-hlist,
#infoPageControls div.box,
#logo,
#footer,
#meta,
#breadCrumb .toHome,
.overviewArea,
.ui-autocomplete .ui-menu-item,
.jstree {

    a,
    a:hover {
        border-bottom: none;
    }
}

#meta,
#footer {
    a {
        color: @PrimaryFontColor;

        &:hover {
            color: @PrimaryCiColor;
            text-decoration: none;
        }
    }
}

a.defaultLink {
    span.infotext_bold {
        color: @ButtonColor;
    }

    &:hover span.infotext_bold {
        color: @HoverColor;
    }

    &.addressBlock {
        border: 1px solid @SecondaryLayoutingColor;

        &:hover {
            border-bottom: 1px solid @SecondaryLayoutingColor;
        }
    }
}

.buttonline a.defaultLink {
    margin-right: 20px;
}

div.itemBox.second>div.bas-inputButton {
    >a.formSubmit.button.text.content {
        margin-top: 5px;
    }
}