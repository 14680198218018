@import '../../utilities/lib.less';

@Icon_Set: url("../../../@{Icon_Set_File}");

.cke_panel_container *:focus {
	outline: none;
}

.cms-container {
  text-align: start;
  background-color: @PrimaryBackgroundColor;
}

.cke_editor tr:hover {
  background-color: #EFEFDE ! important;
}

.cke_contents.cke_reset {
  max-height: 360px ! important;
}

.bas-inputArea .cms-container span, .inlineckeditor, .ProseMirror {
  line-height: 16px;
  p {
    margin: 2px 0;
  }

  ol, ul{
  	&,ol,ul {
	    padding-left: 20px;
	    margin: 12px 0 12px 0;
  	}
  }

  .newPlaceholder {
    -webkit-animation-duration: 4s;
    -webkit-animation-name: highlightTemplatePlaceholder;
    animation-name: highlightTemplatePlaceholder;
    animation-duration: 4s;
  }
}

@keyframes highlightTemplatePlaceholder {
  0% {
    background: @HighlightColor
  }
  100% {
    background: @ButtonColor;
  }
}

.cke_single_page .cke_dialog_contents {
	margin-top: 0 !important;
}

.cke_dialog {
  z-index: @Index_Overlay + 10 !important;
  .cke_dialog_body {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    border: none;
    .cke_dialog_tabs:not(.hidden) {
      ~ .cke_dialog_contents {
        margin-top: 41px;
        border-top: 1px solid @SecondaryLayoutingColor;
      }
      .cke_dialog_tab {
        padding: 10px;
        font-size: 14px;
        background: @TertiaryLayoutingColor;
        border: 1px solid @SecondaryLayoutingColor;
        &:hover {
          background: @PrimaryBackgroundColor;
          border: 1px solid @SecondaryLayoutingColor;
        }
        &, &:hover {
          border-bottom: 0 none;
          font-family: @DefaultFont;
        }
        &_selected {
          &, &:hover {
            background: @PrimaryBackgroundColor;
            border: 1px solid @SecondaryLayoutingColor;
            border-bottom: 0 none;
            font-weight: bold;
            text-decoration: none;
          }
        }
      }
    }
    .cke_dialog_title {
      background: @PrimaryCiColor;
      color: @ButtonFontColor;
      font-size: 16px;
      padding: 10px;
      text-shadow: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border: none;
    }
    .cke_dialog_contents {
      input[type="text"], textarea:not(.mat-input-element) {
        max-width: 100%;
        border: 1px solid @PrimaryLayoutingColor;
        color: @PrimaryFontColor;
        background: @PrimaryBackgroundColor;
        box-shadow: @DefaultInputBoxShadow;
        padding: 1px 5px;
        text-align: left;
        margin-bottom: 5px;
        height: 25px;
        font-size: 14px;
        font-family: @DefaultFont;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        &:hover, &:focus {
          border: 1px solid @PrimaryCiColor;
        }
      }
      input[type="radio"] {
        margin-top: 5px;
        & ~ label {
          line-height: 24px;
        }
      }
      input[type="checkbox"] {
        margin-top: 0;
      }
      textarea {
        height: auto;
      }
      select {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        filter: none;
        background: @PrimaryBackgroundColor;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border: 1px solid @TertiaryCiColor;
        padding: 3px;
        cursor: pointer;
        &:focus, &:active {
          color: @HighlightColor;
        }
      }

      a.cke_dialog_ui_button {
        .defaultButton();
        background: @ButtonColor;
        box-shadow: none;
        border: 0;
        border-radius: 0;
        margin-right: 5px;
        margin-bottom: 5px;
        max-width: 150px;
        > span {
          font-family: @DefaultFont !important;
          text-shadow: none;
          color: @ButtonFontColor;
          display: inline-block;
          white-space: normal;
          line-height: 20px;
        }
      }
    }
    .cke_dialog_close_button {
      width: 20px;
      height: 20px;
      display: inline-block;
      background-repeat: no-repeat;
      background-color: @ButtonColor;
      background-image: @Icon_Set;
      cursor: pointer;
      margin-top: 4px;
      margin-right: 0;
      background-position: @IconClose;
      &:hover {
        background-color: @HoverColor;
      }
    }
    .cke_dialog_footer {
      &, &:hover {
        background: @PrimaryBackgroundColor;
        filter: none;
        outline: none;
        .cke_resizer {
          margin-top: 31px;
          margin-right: 2px;
        }
        .cke_dialog_footer_buttons {
          a.cke_dialog_ui_button.cke_dialog_ui_button_ok {
            .highlightButton();
            border: 0;
            border-radius: 0;
            margin-right: 0;
            margin-bottom: 5px;
            &.cke_disabled {
              &, &:hover, &:focus, &:active {
                background-color: @ButtonInactiveColor;
                cursor: default;
                box-shadow: none;
                > span {
                  cursor: default;
                }
              }
            }
          }
        }
      }
    }
  }
}

html.cke_webtm_clickable {
  &, body, body * {
    cursor: pointer !important;
  }
}

.cke_dialog_contents td.cke_dialog_footer {
  padding-top: 5px;
}

.cke_widget_wrapper {
  &.cke_widget_focused, &:hover, &.cke_widget_selected {
    > .cke_widget_element {
      outline: none !important;
      background: @HighlightColor;
    }
  }
  .cke_widget_editable.cke_widget_editable_focused {
    outline: none !important;
    background: @HighlightColor;
  }
}

span[data-placeholder-id] {
  background: @ButtonColor;
  color: @ButtonFontColor;
  padding: 1px 2px;
}

.inlineckeditor {
  margin: 0;
  word-wrap: break-word;
  &[readonly=readonly] {
    background: @InputInactiveColor;
    color: @InputFontInactiveColor;
  }
}

.cke_dialog_background_cover, .cke_panel {
  z-index: 10001 !important;
}

div.cke, div.ckeditor {
  box-shadow: none;
  .cke_inner {
    .cke_top {
      background: @TertiaryLayoutingColor;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      .cke_toolbox .cke_toolbox_main {
        .cke_toolgroup {
          -webkit-border-radius: 0;
          -moz-border-radius: 0;
          border-radius: 0;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          background: @PrimaryBackgroundColor;
          filter: none;
          border: 1px solid @TertiaryCiColor;
          .cke_button {
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            &:hover, &:active, &.focus {
              background: @SecondaryLayoutingColor;
              filter: none;
              &.cke_button_disabled {
                background: @PrimaryBackgroundColor;
                filter: none;
              }
            }
            &.cke_button_on {
              background: @SecondaryLayoutingColor;
              filter: none;
            }
          }
        }
        .cke_combo {
          .cke_combo_button {
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            filter: none;
            background: @PrimaryBackgroundColor;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            border: 1px solid @TertiaryCiColor;
            .cke_combo_text {
              font-family: @DefaultFont;
              font-size: 13px;
              line-height: 25px;
              cursor: pointer;
              color: @PrimaryFontColor;
              padding-left: 5px;
              text-shadow: none;
            }
            .cke_combo_arrow {
              margin: 10px 0 0;
              border-left: 3px solid transparent;
              border-right: 3px solid transparent;
              border-top: 4px solid @PrimaryFontColor;
            }
          }
          &.cke_combo_on {
            .cke_combo_button {
              border: 1px solid @TertiaryCiColor;
              .cke_combo_text {
                color: @HighlightColor;
              }
              .cke_combo_arrow {
                border-bottom: 4px solid @PrimaryFontColor;
                border-top: 0;
              }
            }
          }
        }
      }
    }
  }
}
.ie {
	.cke_button.cke_button__templateplaceholder {
	  width: 28px;
	  > span {
	    background-size: contain !important;
	    width: 28px;
	  }
	}
}

.firefox, .chrome {
	.cke_button.cke_button__templateplaceholder {
	  width: 28px;
	  > span {
	    background-size: initial !important;
	    width: 28px;
	  }
	}
}
