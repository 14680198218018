@import '../../utilities/lib.less';
@imgPath: '../../../img';
.jspVerticalBar {

	background-color: transparent;

	.jspDrag {
		background: url('@{imgPath}/scrollbar.png') no-repeat center center @SecondaryLayoutingColor ! important;
		border-radius: @BorderRadiusDefault;
		margin: 0 1px ! important;
		border: 1px solid @SecondaryLayoutingColor;
		&.jspHover {
			border: 1px solid @PrimaryLayoutingColor;
		}

		&.jspActive {
			border: 1px solid @PrimaryLayoutingColor;
		}
	}
}

.jspHorizontalBar {
	display: none;
}
.jspVerticalBar > .jspArrow {
	border: 0 none;
	
	&.jspArrowUp, &.jspArrowDown {
		background-image: url('@{imgPath}/scrollbar_controls_vertical.png');
		background-repeat:no-repeat;
		background-color: @PrimaryBackgroundColor;
	}
	
	
	&.jspArrowUp , &.jspDisabled.jspArrowUp , &.jspDisabled.jspArrowUp:hover {
		background-position: center 0;
	}

	&.jspArrowUp:hover {
		background-position: center -14px;
	}

	&.jspArrowDown , &.jspDisabled.jspArrowDown , &.jspDisabled.jspArrowDown:hover {
		background-position: center -41px;
	}

	&.jspArrowDown:hover {
		background-position: center -27px;
	}
}

.jspTrack {
	background-color: @PrimaryBackgroundColor ! important;
}

