@import '../../utilities/lib.less';
#ui-datepicker-div {
	border: 1px solid @SecondaryLayoutingColor;
	width: 232px;
	border-radius: @DefaultBorderRadius;
	.ui-priority-secondary , .ui-widget-content .ui-priority-secondary , .ui-widget-header .ui-priority-secondary , .ui-state-disabled , .ui-widget-content .ui-state-disabled {
		opacity: 1;
	}

	.ui-datepicker-header span {
		line-height: normal;
	}

	td.ui-datepicker-week-col , th.ui-datepicker-week-col , tr:hover td.ui-datepicker-week-col {
		text-align: center;
		color: @PrimaryLayoutingColor;
		background: @SecondaryLayoutingColor ! important;
		vertical-align: middle;
	}

	.ui-state-default {
		color: @PrimaryFontColor;
		font-size: 12px;
		background: @TertiaryLayoutingColor;
		border: 1px solid @SecondaryLayoutingColor;
		padding: 0.2em;
		font-weight: bold;
		width: calc(22px + 0.4em);
		line-height: 16px;
		text-align: center;
	}
	
	.ui-datepicker-unselectable.ui-datepicker-today span, .ui-datepicker-today a {
		border: 1px solid @PrimaryCiColor;
		background: @PrimaryCiColor;
		color: @ButtonFontColor;
		& , &:hover {
			border-color: @PrimaryCiColor;
		}
	}
	
	.ui-datepicker-unselectable {
		& , &:hover {
			opacity: 1;
			span {
				border-color: @ButtonInactiveColor;
				background-color: @PrimaryBackgroundColor;
				color: @ButtonInactiveColor;
			}
		}
	}

	.ui-priority-secondary {
		color: @PrimaryLayoutingColor;
		font-weight: normal;
	}

	.ui-datepicker-current {
		color: @PrimaryFontColor;
		font-weight: bold;
		float: none;
	}

	.ui-datepicker-current-day {
		background: none;
		a {
			border: 1px solid @HighlightColor;
			font-weight: bold;
			color: @ButtonFontColor;
			background: @HighlightColor;
			&:hover {
				border-color: @HighlightColor;
			}
		}
	}


	.early-start-not-selected {
		a {
			color: @ErrorColor;
		}
		&.ui-datepicker-current-day {
			a {
				background: @ErrorColor;
				color: @ButtonFontColor;
			}
		}
	}

	a:hover {
		border-radius: 1px;
		border-color: @PrimaryLayoutingColor;
		color: @PrimaryFontColor;
		.ui-datepicker-next-hover , .ui-datepicker-prev-hover {
			background: green ! important;
			color: @PrimaryFontColor;
			font-weight: bold;
			border: 0 ! important;
		}
	}

	.ui-datepicker-next , .ui-datepicker-prev {
		span {
			margin-top: -9px;
			margin-left: -8px;
		}
	}

	.ui-datepicker-next {
		height: 16px;
		width: 16px;
		cursor: pointer;
	}

	.ui-datepicker-header {
		padding: 2px 0;
		background-color: @PrimaryLayoutingColor;
	}

	.ui-datepicker-prev , .ui-datepicker-next , .ui-widget-header .ui-icon.ui-icon-circle-triangle-w , .ui-widget-header .ui-icon.ui-icon-circle-triangle-e {
		width: 20px;
		height: 20px;
		top: 3px;
	}

	.ui-widget-header {
		background-color: @PrimaryLayoutingColor;
		border: none;
		border-radius: 1px 1px 0 0;
		a {
			border-bottom: none;
		}
		
		span.ui-datepicker-month {
			color: @ButtonFontColor;
		}

		.ui-state-disabled {
			opacity: 1;
			.ui-icon.ui-icon-circle-triangle-e , .ui-icon.ui-icon-circle-triangle-w {
				& , &:hover {
					background-color: @ButtonInactiveColor;
				}
			}
		}

		.ui-icon.ui-icon-circle-triangle- {
			&w {
				background-image: url("../../../@{Icon_Set_File}");
				width: 20px;
				height: 20px;
				background-color: @ButtonColor;
				background-position: @IconLeft;
				margin: 0;
				left: 0;
				top: 0;
				&:hover {
					background-color: @HoverColor;
				}
			}

			&e {
				background-image: url("../../../@{Icon_Set_File}");
				background-position: @IconRight;
				background-color: @ButtonColor;
				width: 20px;
				height: 20px;
				margin: 0;
				left: 0;
				top: 0;
				&:hover {
					background-color: @HoverColor;
				}
			}
		}
	}

	.ui-datepicker .ui-datepicker-title {
		margin: 0 2.7em;
	}

	th , td.ui-datepicker-week-col , th.ui-datepicker-week-col , tr:hover td.ui-datepicker-week-col {
		font-size: 10px;
		background-color: @SecondaryLayoutingColor ! important;
		color: @PrimaryFontColor;
		font-weight: bold;
		text-align: center;
	}

	.ui-state-default:hover {
		background: @SecondaryLayoutingColor;
		color: @PrimaryFontColor;
	}
}

.ui-widget-content {
	border: 1px solid @SecondaryLayoutingColor;
	.ui-state-highlight {
		background: @PrimaryBackgroundColor;
	}
}

.ui-datepicker {
	select.ui-datepicker-year {
		width: auto;
		height: 20px;
	}

	table {
		margin: 0;
	}

	.ui-datepicker-buttonpane {
		margin: 0 0 2px 0;
		button {
			margin: 0;
		}
	}
}

#ui-datepicker-div .ui-datepicker select.ui-datepicker-month , #ui-datepicker-div .ui-datepicker select.ui-datepicker-year , .ui-datepicker-year {
	margin: 1px 0;
}

.ui-state-hover {
	&.ui-datepicker-prev-hover {
		left: 2px;
	}

	&.ui-datepicker-next-hover {
		right: 2px;
	}
}

