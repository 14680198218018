@import '../../utilities/lib.less';
.legend {
	.legendItem {
		display: block;
		margin-bottom: @GuttingDefault;
	}

	&.horizontal {
		.legendItem {
			font-size: 12px;
			padding: @GuttingDefault 0;
		}

		.legendHeader {
			margin: @GuttingDefault 0;
			padding: 0;
			font-weight: bold;
			font-size: 12px;
		}
	}

	> * {
		float: none;
	}
}

.legendText {
	padding-left: @GuttingDefault;
	vertical-align: middle;
}

.legendElement {
	width: 40px;
	text-align: center;
	margin-top: 0;
	.iconArchiveLegend , .icon {
		width: auto;
		height: auto;
		margin-right: 1px;
		margin-top: 1px;
	}

	> div.delete_small {
		padding: 0;
	}

	.icon:hover .iconContainer {
		background-color: @PrimaryCiColor;
	}
}

