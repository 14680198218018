@import '../utilities/lib.less';
@Icon_Set: url("../../@{Icon_Set_File}");
.jstree a {
	padding: 0 2px ! important;
	border: 0 ! important;
	&.inactive {
		background: transparent ! important;
		color: @ButtonInactiveColor ! important;
	}
	&.jstree-clicked , &.jstree-clicked.jstree-hovered {
		background: @PrimaryCiColor ! important;
		color: @ButtonFontColor ! important;
	}
	&.jstree-hovered {
		background-color: transparent ! important;
	}
}

.jstree-contextmenu {
	li {
		> a {
			line-height: 16px;
			padding: 2px 4px 2px 30px;
			border: none;
			> ins:empty {
				width: 20px;
				height: 20px;
			}

			> .vakata-contextmenu-sep {
				height: 16px;
			}
		}

		&.vakata-context-hover ins.jsTreeIcon {
			background-color: @HoverColor;
		}

		&.vakata-contextmenu-disabled ins.jsTreeIcon {
			background-color: @ButtonInactiveColor;
		}

		ins.jsTreeIcon {
			background-color: @ButtonColor;
			background-repeat: no-repeat;
			background-image: @Icon_Set;
			&.jsTreeAdd {
				background-position: @IconNew;
			}

			&.jsTreeAddUserArea {
				background-position: @IconNew;
			}

			&.jsTreeEdit {
				background-position: @IconEdit;
			}

			&.jsTreeDelete {
				background-position: @IconDelete;
			}
		}
	}
}
#vakata-contextmenu.jstree-default-context {
	a:hover, .vakata-hover > a {
		padding: 1px 4px;
		outline: 1px solid #aecff7;
		border: none;
	}
	ul li.jstree-contextmenu-disabled { // mind the ul
		a,  a:hover {
			border-bottom: 1px solid @PrimaryFontColor;
		}
	}
}

#areaTree.jstree {
	li.invalid a {
		color: @ErrorColor;
	}
}


