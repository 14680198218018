@import '../../utilities/lib';
div.contentContainer section.bas-formSection div.field #newsletterPreview {
	width: 660px;
	h1,h2,h3,h4,h5 {
		background-color:@White;
		padding:0;
		margin:0;
	}
	#bigpreview {
		border: 1px solid @SecondaryLayoutingColor;
		margin-top: 15px;
		padding: 10px;
	}
}

#newsletterPreview {
	border: 2px solid @SecondaryLayoutingColor;
}

#template {
	margin: 0 auto;
}

.rectanglesActive {
	outline: solid 2px @PrimaryCiColor;
}

.preview-highlight {
	outline: solid 2px @PrimaryCiColor;
}

#newsletterPreview ul {
	-webkit-padding-start: 1em;
}

#newsletterPreview td {
	line-height: inherit;
}
