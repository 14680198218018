@import "../../utilities/lib.less";

@Icon_Set: url("../../../@{Icon_Set_File}");
div.calendar-div {
	padding: 2px;
	border: 1px solid @SecondaryLayoutingColor;

	thead th {
		color: @PrimaryFontColor;
	}

	.calendarControls {
		.iconContainer {
			background-image: @Icon_Set;
			background-repeat: no-repeat;
			background-color: @ButtonColor;
			height: 20px;
			width: 20px;
			display: inline-block;

			&:hover {
				background-color: @HoverColor;
			}
		}

		&.inactive {
			cursor: default;
		}

		&#prevMonth {
			float: left;

			.iconContainer {
				background-position: @IconLeft;
			}

			&.inactive, &.inactive:hover {
				.iconContainer {
					background-color: @ButtonInactiveColor;
				}
			}
		}

		&#nextMonth {
			float: right;
			margin-right: 0;

			.iconContainer {
				background-position: @IconRight;
			}

			&.inactive, &.inactive:hover {
				.iconContainer {
					background-color: @ButtonInactiveColor;
				}
			}
		}
	}

	a.calendarControls {
		border-bottom: none;
	}

	.calendar-head {
		padding: 5px;
		background-color: @SecondaryLayoutingColor;
	}

	.calendar-title {
		text-align: center;
		font-weight: bold;
		padding: 4px 0;
	}

	table.calendar {
		color: @PrimaryFontColor;

		.item {
			width: 100%;
			box-sizing: border-box;
		}

		.itemBox {
			padding-right: 0;
			width: 100%;

			> a {
				width: 100%;
				border-bottom: 1px solid transparent ! important;
				color: @SuccessColor;

				> span {
					font-weight: bold;
				}
			}
		}

		.calendar-weekday > .item.horizontal {
			display: flex;
		}

		thead th {
			text-align: center;
			border-bottom: none;
			font-size: 11px;
		}

		tbody td {
			border-width: 1px;
			border-style: solid;
			border-color: transparent;
			font-size: 12px;
			width: 30px;
			text-align: center;

			&.today .itemBox {
			}
		}

		.weekOfYear, .weekOfYearHeader {
			color: @PrimaryLayoutingColor;
		}
	}

	td.calendar_early, td.calendar_valid, td.calendar_booked {
		span {
			padding-right: 3px;
			float: right;
		}
	}

	div.item.horizontal {
		border: 1px solid @SecondaryLayoutingColor;

		> * {
			margin-right: 0;
		}

		img {
			margin: 4px 0 0 4px;
			display: block;
		}
	}

	.today > div.item.horizontal {
		border-color: @HighlightColor;
	}

	.checkboxArray {
		float: left;
		margin-left: 0;
	}

	.calendar_weekdays, tbody tr:hover .calendar_weekdays {
		color: @PrimaryFontColor;
		line-height: normal;
		font-size: 10px;
		font-weight: bold;
		text-align: center;
	}

	.calendar_weekdays.we, tbody tr:hover .calendar_weekdays.we {
		background: @PrimaryLayoutingColor ! important;
		color: @PrimaryFontColor;
	}

	td.calendar, .calendar_today, tbody .calendar_valid, tbody .calendar_early {
		vertical-align: middle;
		text-align: center;
		width: 30px;
		height: 30px;
	}

	.calendar_checkbox {
		height: auto;
	}

	tbody {
		.calendar, tr:hover td.calendar {
			color: @PrimaryFontColor;
		}

		.calendar_valid, tr:hover td.calendar_valid {
			color: @SuccessColor;
			border-color: @PrimaryLayoutingColor;
		}

		.calendar_early, tr:hover td.calendar_early {
			color: @SecondaryCiColor;
			border-color: @PrimaryLayoutingColor;
		}

		td.selected {
			.itemBox {
				background-color: @PrimaryCiColor;

				> a {
					color: @ButtonFontColor;
				}
			}

			&:hover .itemBox {
				background-color: @SecondaryLayoutingColor;
			}
		}

		td.calendar {
			background: @TertiaryLayoutingColor;
			border: 1px solid @SecondaryLayoutingColor;
			font-size: 12px;

			&:hover {
				background: @SecondaryLayoutingColor;
				border-color: @PrimaryLayoutingColor;
			}
		}

		tr.header, tr.header:hover {
			td {
				background: @SecondaryLayoutingColor;
				padding: @GuttingDefault;
			}

			span.text {
				color: @PrimaryFontColor;
			}

			td, td.calendar_valid {
				color: @Black;
			}
		}

		tr:hover td.calendar_valid {
			color: @Black;
		}

		.calendar_early, .early-start-not-selected {
			.itemBox a {
				color: @ErrorColor;
			}

			border-color: @PrimaryLayoutingColor;
		}
	}

	div.bas-inputCheck {
		margin: 0;
		padding: 0;
	}

	td {
		input[type="checkbox"] {
			border-right-width: 0;
			margin: @GuttingDefault 0 0 3px;
			padding: 0;
			float: none;
		}

		.itemBox span.text {
			margin: 2px 0 0 3px;
			padding: 0;
		}

		.item.horizontal .itemBox {
			margin: 0;
			float: none
		}

		&.calendar_early, &.calendar_valid {
			&:hover {
				background: @SecondaryLayoutingColor;
			}
		}
	}

	.calendar_weekdays.we, tbody .calendar_weekdays.we, .calendar_weekdays, tbody .calendar_weekdays, tbody tr:hover .calendar_weekdays.we {
		background-color: @PrimaryBackgroundColor ! important;
		color: @Black;
	}
}

.legendItem .legendElement {
	&.calendar_valid, &.calendar_today, &.calendar_early {
		height: 20px;
		width: 25px;
		background-color: @PrimaryBackgroundColor;
		border-radius: @BorderRadiusDefault;
	}

	> div.calendar_Legend_Text {
		font-size: 12px;
		line-height: 10px;
		display: block;
		padding: 4px 0 0 6px;
		font-weight: bold;
		text-align: left;
	}

	&.calendar_valid {
		border: 1px solid @PrimaryLayoutingColor;

		> div {
			color: @SuccessColor;
		}
	}

	&.calendar_today {
		border: 1px solid @HighlightColor;
	}

	&.calendar_early {
		border: 1px solid @PrimaryLayoutingColor;

		> div {
			color: @ErrorColor;
		}
	}

	&.calendar_booked {
		width: 26px;
		height: 15px;
	}
}

tbody tr:hover td {
	background: none;
}

table.calendar tbody, .legendItem .legendElement {
	.calendar_valid, .not-selected, .early-start-not-selected {
		> .horizontal {
			border: 1px solid @PrimaryLayoutingColor !important;
		}
	}

	tr:hover {
		td.calendar_valid {
			> .horizontal {
				border: 1px solid @PrimaryLayoutingColor !important;
			}
		}
	}

	.itemBox {
		color: @Black;
	}
}

.legendItem .legendElement.calendar_today, table.calendar td.today .itemBox {
	font-weight: bold;
}
