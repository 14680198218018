@import "../utilities/lib.less";

#basket {
	position: relative;
}

#basketTrigger {
	position: relative;
	background: none;
	z-index: @Index_QuicknavTrigger;
	display: inline-block;
	border: 1px solid transparent;
	padding: 5px 5px 0 5px;
	margin-top: -5px;

	&.hover, &:hover {
		z-index: @Index_QuicknavTrigger + 2;
		border: 1px solid @PrimaryLayoutingColor;
		border-bottom: none;

		+ #basketContent {
			z-index: @Index_Quicknav + 1 ! important;
		}
	}

	&:not(.isFilled), &:not(.isFilled):hover {
		border: 1px solid transparent;
	}
}

#basketContent {
	display: none;
	border: 1px solid @PrimaryLayoutingColor;
	background-color: #ffffff;
	z-index: @Index_Quicknav ! important;
	padding: 0 5px;
	box-shadow: 0 -2px 5px -3px @PrimaryLayoutingColor;
	min-width: 150px;
	position: absolute;
	top: 29px;
	left: 0;

	.basketElement {
		padding: 5px 0;
		border-bottom: 1px solid @PrimaryLayoutingColor;
		height: 20px;
		width: 100%;
		line-height: 20px;

		span {
			color: @PrimaryLayoutingColor;
		}

		&:last-of-type {
			border-bottom: none;
		}
	}

	#toBasketLink {
		text-align: center;

		span {
			color: @HighlightColor;
		}

		&:hover span {
			color: @HoverColor;
			border-bottom: 1px solid @HoverColor
		}
	}
}

