@import "../utilities/lib.less";
.jquery_tabs {
	ul.tabs-list {
		top: 1px;
		li {
			a {
				background: @TertiaryLayoutingColor;
				border: 1px solid @TertiaryLayoutingColor;
				&:hover {
					background: @PrimaryBackgroundColor;
					border: 1px solid @TertiaryLayoutingColor;
				}

				& , &:hover {
					border-bottom: 0 none;
				}
			}

			&.current a {
				& , &:hover {
					border: 1px solid @TertiaryLayoutingColor;
					border-bottom: 0 none;
				}
			}
		}
	}

	.bas-formBox > .bas-label label {
		margin-left: 10px;
	}
}

.tabs .tabContent {
	border: 1px solid @TertiaryLayoutingColor;
	border-bottom-left-radius: @DefaultBorderRadius;
	border-bottom-right-radius: @DefaultBorderRadius;
	border-top-right-radius: @DefaultBorderRadius;
	padding-right: 10px;
}

