@import '../utilities/lib.less';
.webtm-attribute:last-child , .webtm-typeSpecific {
	border-bottom: 1px solid @SecondaryLayoutingColor;
}

#editMaskContainer *[data-webtmid] {
	position: relative;
	&:not(.panel).hover {
		box-shadow: 0px -1px 0 4px var(--color--ci--secondary--alpha-25);
		background-color: var(--color--ci--secondary--alpha-25);
		* {
			opacity: 1;
			cursor: pointer ! important;
		}
	}
	&.locked:not(.hover) {
		background: @TertiaryLayoutingColor;
		box-shadow: 0px -1px 0 4px @TertiaryLayoutingColor;
		> *:not(.webtm-iconContainer) {
			opacity: 0.5;
		}
	}
	div.panelHeader span.panelLabel:hover {
		box-shadow: 0px -1px 0 4px var(--color--ci--secondary--alpha-25);
		background-color: var(--color--ci--secondary--alpha-25);
		:after {
		 	content: "";
		    width: 17px;
		    height: 17px;
			background: url("../../@{Icon_Set_File_Black}") @IconEdit ;
		    display: inline-block;
		    margin-left: 5px;
		    margin-top: -1px;
		}
	}
	header.groupSeparator{
		&:hover {
			&:after {
			 	content: "";
			    width: 17px;
			    height: 17px;
				background: url("../../@{Icon_Set_File_Black}") @IconEdit ;
			    display: inline-block;
			    margin-left: 5px;
			    margin-top: -3px;
			}
		}
	}
}

.webtm {
	&.webtmAdmin {
		#overviewContainer {
			div.bas-formBox , .field {
				margin: 5px 0;
			}
		}

		.bas-formSection .bas-formBox header {
			font-size: 18px;
		}

		.overviewArea {
			border: 2px solid @SecondaryCiColor;
		}

		#webtmModeDecorator {
			background-color: var(--color--ci--secondary--alpha-25);
			color: @SecondaryCiColor;
			padding: 10px 0;
			width: 100%;
			line-height: 16px;
			font-size: 16px;
			text-align: center;
			+ .overviewButtons {
				border-top: 2px solid @SecondaryCiColor;
			}
		}

		.overviewButtons + #webtmModeDecorator {
			border-top: 2px solid @SecondaryCiColor;
		}
	}
}

.webtm-attributeTupel {
	label.requiredMarker {
		position: relative;
		&:before {
			content: "*";
			font-size: 25px;
			color: @HighlightColor;
			top: 0;
			left: -15px;
			position: absolute;
		}
	}
}

