@import '../../utilities/lib.less';

.bas-formBox.layout-66-33,
.bas-formBox.layout-80-20 {
	label[for]:hover {
		color: @HighlightColor;
	}
}

.werbemittel-container {
	border: 1px solid @GanttBorderColor;

	&.KONZERN .werbemittel-header {
		background-color: @NationalGanttBackground;
	}

	&.SPERRFRIST .werbemittel-header {
		background-color: @SperrfristGanttBackground;
	}

	&.OWN .werbemittel-header {
		color: @PrimaryCiColor;
		background-color: @OwnGanttBackground;

		.icon:hover {
			&.edit .iconContainer {
				background-position: -41px -1px;
			}

			&.delete .iconContainer {
				background-position: -81px -1px;
			}

			.iconContainer {
				border: 1px solid @PrimaryCiColor;
				box-sizing: border-box;
			}
		}
	}

	&.PARTNER.teilgenommen .werbemittel-header {
		color: @PrimaryBackgroundColor;
		background: @NormalGanttBackground;
	}

	.werbemittel-header {
		color: @PrimaryFontColor;
		background: @NormalNotFollowedGanttBackground;
		border-bottom: 1px solid @GanttBorderColor;
	}

	.gruppenbudget {
		background: @TertiaryLayoutingColor;
	}
}

.wmDialog {
	.werbemittel-vorschaubild {
		img {
			border: 1px solid @PrimaryCiColor;
		}

		>.dummy {
			background-color: #d0d0d0;
		}
	}
}