@import '../utilities/lib.less';

.item.linkGroup {
	padding: 4px @GuttingDefault;

	>a,
	>span {
		padding: 2px 8px;
		border-bottom: none;
	}

	a.icon {
		padding: 0;
	}

	a.editPage,
	span.mtPageCurrent {
		height: auto;
		display: inline-block;
		border: 1px solid @Black;
		margin: 2px;
		padding: 3px @GuttingDefault;
		border-radius: @BorderRadiusDefault;
		text-decoration: none;
	}

	a.editPage span {
		padding: 0;
	}

	span.mtPageCurrent {
		cursor: default;
	}
}

#col3_content .item.linkGroup {
	a.icon.text {
		border: 1px solid @PrimaryFontColor  ! important;
		display: inline-block;
	}

	a.notCustomizable {
		color: @PrimaryLayoutingColor;
	}

	a.notCustomizable:hover,
	span.mtPageCurrent.notCustomizable {
		background-color: @PrimaryLayoutingColor;
		color: @SecondaryFontColor
	}

	a.complete {
		color: @SuccessColor;
	}

	a.complete:hover,
	span.mtPageCurrent,
	span.mtPageCurrent.complete {
		background-color: @SuccessColor;
		color: @ButtonFontColor;
	}

	a.incomplete {
		color: @ErrorColor;
	}

	a.incomplete:hover,
	span.mtPageCurrent.incomplete {
		background-color: @ErrorColor;
		color: @ButtonFontColor;
	}
}

.defaultLink.paginationFastJump,
.defaultLink.paginationPageJump,
.paginationLinks .paginationCurrentPage,
.paginationCurrentPage,
.paginationDots {
	height: 24px;
	line-height: 16px;
	display: inline-block;
	text-align: center;
	vertical-align: top;
	padding: 0 2px;
	background: none;
}

.paginationPageJump,
.paginationFastJump.first,
.paginationDots {
	border-right: 1px dotted @SecondaryLayoutingColor;
}

.tablePagination {
	background-color: @TertiaryLayoutingColor;
	border: solid 1px @SecondaryLayoutingColor;
	border-radius: @DefaultBorderRadius;
}

.tablePagination,
.pagination {
	text-align: center;
	padding: 10px;
	margin-top: -1px;

	&:after {
		content: '';
		display: block;
		clear: both;
	}

	>* {
		display: inline-block;
	}

	.linkGroup {
		padding: 0 0;

		.paginationCurrentPage {
			margin: 0 1px;
			font-weight: bold;
		}

		a>span {
			line-height: 16px;
		}
	}

	.resultsPerPageWrapper {
		float: right;

		>* {
			display: inline-block;
		}

		.bas-inputSelect {
			margin-left: 10px;
		}
	}

	.pageLength {
		margin-top: 8px;
	}
}

.paginationFastJump.icon {
	&.previous {
		.iconContainer {
			background-position: -40px -60px;
		}
	}

	&.next {
		.iconContainer {
			background-position: -60px -60px;
		}
	}
}

.paginationPageJump {

	&.predecessorToCurrent,
	&.last {
		border-right: none;
	}
}