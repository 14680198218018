@import '../utilities/lib.less';
@Icon_Set: url("../../@{Icon_Set_File}");
#wofWrapper {
	z-index: @Index_Header + 2;
	.wofSectionGroup {
		background-color: @PrimaryBackgroundColor;
		width: 30px;
		+ .wofSectionGroup.wofSeparator {
			border-top: 10px solid @PrimaryBackgroundColor;
			height: 42px;
		}

		&:first-child {
			border-top: 0 none;
		}

		&:not(:last-child) {
			height: 32px;
			border-bottom: 2px solid @PrimaryBackgroundColor;
		}

		&:last-child {
			height: 30px;
		}

		&.wofSeparator {
			cursor: default;
		}

		&:not(.wofSeparator).inviewport {
			&:hover {
				.wofElement {
					color: @ButtonFontColor;
				}
			}

			.wofElement {
				background: @HoverColor;
				&:before {
					background-color: @HoverColor;
				}
			}
		}

		&:not(.wofSeparator).containsErrors {
			.wofElement {
				background: @ErrorWidget_ErrorColorBackground;
				&:before {
					background-color: @ErrorWidget_ErrorColorBackground;
				}
			}
		}

		&:not(.wofSeparator) .wofElement {
			color: @ButtonColor;
			line-height: 30px;
			background: @ButtonColor;
			&:before {
				width: 30px;
				height: 30px;
				background: @ButtonColor url('../../img/icon_sectionScrollHelper_arrow_small.png');
				color: @ButtonFontColor;
			}
		}

		&.wofSeparator .wofElement {
			color: @ButtonColor;
			line-height: 30px;
			background: @ButtonColor;
			&:before {
				width: 32px;
				height: 30px;
				background: @ButtonColor;
			}
		}
	}

	&:hover {
		.wofSectionGroup {
			.wofElement {
				color: @ButtonFontColor;
				margin-left: 32px;
				padding-left: 10px;
				padding-right: 15px;
				&:before {
					left: -32px;
					background-color: @ButtonColor;
				}
			}

			&:not(.wofSeparator) {
				&.inviewport .wofElement {
					background: @HoverColor;
					&:before {
						background-color: @HoverColor;
					}
				}

				&:hover .wofElement {
					background-color: @HoverColor;
					&:before {
						background-color: @HoverColor;
					}
				}

				&.containsErrors {
					.wofElement {
						background: @ErrorWidget_ErrorColorBackground;
						&:before {
							background-color: @ErrorWidget_ErrorColorBackground;
						}
					}
				}
			}
		}
	}
}

div.wofArrow {
	background-color: @HoverColor;
	background-image: @Icon_Set;
	background-position: -60px -100px;
	&.wofArrowTop {
		transform: rotate(180deg);
	}

	&.max {
		background-color: @ButtonInactiveColor;
	}
}

