@import "../utilities/lib.less";

@Icon_Set: url("../../@{Icon_Set_File}");
input[type=text], input[type=password], select:not([size]), textarea:not(.mat-input-element), .inlineckeditor {
	max-width: 100%;
	border: 1px solid @PrimaryLayoutingColor;
	border-radius: @DefaultBorderRadius;
	color: @PrimaryFontColor;
	background: @PrimaryBackgroundColor;
	box-shadow: @DefaultInputBoxShadow;
	font-family: @DefaultFont;

	&:hover, &:focus {
		&:not([readonly]) {
			border: 1px solid @PrimaryCiColor;
		}
	}
}

.jumpedAtElement:focus {
	-webkit-animation-duration: 1s;
	-webkit-animation-name: onFocusBlinking;
	animation-name: onFocusBlinking;
	animation-duration: 1s;
}

@keyframes onFocusBlinking {
	100% {
		background: white;
	}
	0% {
		background: @HoverColor;
	}
}

textarea, .inlineckeditor {
	padding: 4px 5px;
	border-radius: @BorderRadiusDefault * 2;
}

input.datepicker[type=text], input.clockpicker[type=text] {
	border-right: 0 none;
	margin-right: 0;
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;

	&:hover, &:focus {
		border-right: 0 none;

		& + img.ui-datepicker-trigger & + .clockpicker-trigger {
			border-color: @PrimaryCiColor;
		}
	}

	&.cols1 {
		width: calc(@ColsInput1 - @ColsDatepickerWidth);
	}

	&.cols2 {
		width: calc(@ColsInput2 - @ColsDatepickerWidth);
	}

	&.cols3 {
		width: calc(@ColsInput3 - @ColsDatepickerWidth);
	}

	&.cols4 {
		width: calc(@ColsInput4 - @ColsDatepickerWidth);
	}

	&.cols5 {
		width: calc(@ColsInput5 - @ColsDatepickerWidth);
	}
}

input, select, textarea {
	&[disabled], &[disabled]:hover, &[disabled]:focus, &.readonly {
		background: @InputInactiveColor;
		border: 1px solid @PrimaryLayoutingColor;

		&, option, + label {
			color: @InputFontInactiveColor;
		}

		option {
			background: @InputInactiveColor;
		}
	}
}

input, select {
	height: 25px;
	border-radius: @DefaultBorderRadius;
}

select {
	&[size] {
		height: auto;
	}

	&.cols6 {
		width: 100%;
	}

	option {
		padding: 0 @GuttingDefault;
		background: @PrimaryBackgroundColor;

		&[disabled], &.disable {
			color: @InputFontInactiveColor;
		}
	}
}

div.itemBox table.tabulation td.col .bas-inputText input {
	margin-bottom: 0;
}

div.checkbox.lockcheckbox {
	width: 20px;
	height: 20px;
	background-image: @Icon_Set;
	background-repeat: no-repeat;
	background-position: @IconUnlock;
	background-color: @ButtonColor;
	cursor: default;

	&:hover, &.checked {
		cursor: pointer;
	}

	&:hover {
		background-color: @HoverColor;
	}

	&.checked {
		background-position: @IconLock;
	}

	&.inactive {
		background-color: @ButtonInactiveColor;
	}
}

div.checkboxArray {
	vertical-align: middle;
}

textarea, input[type=text], select, .inlineckeditor, bwc-date-picker {
	&.colsQuarter {
		width: @ColsInputQuarter;
	}

	&.colsThird {
		width: @ColsInputThird;
	}

	&.colsHalf {
		width: @ColsInputHalf;
	}

	&.colsTwoThird {
		width: @ColsInputTwoThird;
	}

	&.cols12 {
		width: @ColsInput12;
	}

	&.cols1 {
		width: @ColsInput1;
	}

	&.cols2 {
		width: @ColsInput2;
	}

	&.cols3 {
		width: @ColsInput3;
	}

	&.cols4 {
		width: @ColsInput4;
	}

	&.cols5 {
		width: @ColsInput5;
	}

	&.cols6 {
		width: @ColsInput6;
	}
}

input {
	&.checkbox, &.radio, &[type="checkbox"], &[type="radio"] {
		margin-top: -2px;
	}

	&.cols_tele_1, &.cols_tele2, &.cols_tele3 {
		padding-right: 0;
	}

	&.cols_tele4 {
		margin-left: @GuttingDefault;
	}

	&.cols_tele_1 {
		width: @ColsInputTele1;
	}

	&.cols_tele_2 {
		width: @ColsInputTele2;
	}

	&.cols_tele_3 {
		width: @ColsInputTele3;
	}

	&.cols_tele_4 {
		width: @ColsInputTele4;
	}

	&.cols_tele_34 {
		width: @ColsInputTele34;
	}

	&.colsRegNr1 {
		width: @ColsInputRegNr1;
	}

	&.colsRegNr2 {
		width: @ColsInputRegNr2;
	}

	&.colsRegNr3, &.colsRegNr4 {
		width: @ColsInputRegNr3;
	}

	&.colsRegNr5 {
		width: @ColsInputRegNr5;
	}

	&.cols_firmierung {
		width: @ColsInputFirmierung;
	}

	&.colsAda1, &.colsAda2, &.colsAda3 {
		width: @ColsInputAda1;
	}

	&[type="file"] {
		background: @PrimaryBackgroundColor;
		box-shadow: @DefaultInputBoxShadow;
		border: 1px solid @PrimaryLayoutingColor;
		margin-bottom: 3px;
		padding: 1px;

		&:disabled {
			background: @SecondaryLayoutingColor;
		}
	}

	&[type=checkbox].lockcheckbox {
		visibility: hidden;
		position: absolute;
	}

	&[type="text"].translation {
		margin-left: @GuttingDefault;
	}
}

.bas-inputArea {
	select[size], select[multiple], input[type=radio], input[type=checkbox] {
		height: auto;
		display: inline-block;
	}

	input[type=submit] {
		height: auto;
		white-space: normal;
	}
}

.bas-inputButton, .bas-inputCheck > * {
	display: inline-block;
}

.bas-inputCheck {
	&.noPadding {
		margin-right: 0;

		input.noPadding {
			margin-right: 0;
		}
	}

	span {
		margin-top: 0 ! important;
	}

	&.containsSVG {

		label svg * {
			stroke: @ButtonColor;
		}

		&:hover label svg * {
			stroke: @HoverColor;
		}

		&, &:hover {
			input[type=checkbox], input[type=radio] {
				&[disabled] {
					cursor: auto;

					+ label svg {
						cursor: auto;

						* {
							stroke: @ButtonInactiveColor;
						}
					}
				}
			}
		}

		span {
			vertical-align: middle;
			line-height: 25px;
		}
	}
}

div.cols1 .bas-inputButton {
	display: block;
}

.bas-formSection.search select {
	width: 55px;
	float: right;
}

.ym-g50 .panel {
	input, select, textarea {
		&.cols1, &.cols2 {
			width: 253px;
		}
	}

	input.datepicker {
		&.cols1, &.cols2 {
			width: 228px;
		}
	}
}

.bas-inputText {
	position: relative;
}

.dialog img.radio-select-img-item {
	margin-top: 15px;
}

img.radio-select-img-item {
	cursor: pointer;
	transition: all 0.2s linear;
	-moz-transition: all 0.2s linear;
	-webkit-transition: all 0.2s linear;

	&.item-checked {
		&, &:hover {
			border-radius: 50%;
		}
	}

	&:hover {
		border-radius: 50%;
	}
}

.positive {
	+ img.radio-select-img-item {
		&.item-checked {
			&, &:hover {
				background-color: @FeedbackPositiveColor;
			}
		}

		&:hover {
			background-color: var(--color--base--green--alpha-40);
		}
	}
}

.neutral {
	+ img.radio-select-img-item {
		&.item-checked {
			&, &:hover {
				background-color: @FeedbackNeutralColor;
			}
		}

		&:hover {
			background-color: var(--color--base--yellow--alpha-40);
		}
	}
}

.negative {
	+ img.radio-select-img-item {
		&.item-checked {
			&, &:hover {
				background-color: @FeedbackNegativeColor;
			}
		}

		&:hover {
			background-color: var(--color--base--red--alpha-40);
		}
	}
}

.selectionGroup {
	> .selection, > .tooltipTrigger {
		margin: 0 30px 15px 0;
	}

	.selection {
		input {
			&:hover {
				~ label.selectionArea, ~ label.desc {
					color: @HoverColor;
				}

				~ label.selectionArea:not([svg]) {
					border: 2px solid @HoverColor;
				}
			}

			&:checked {
				&, &:hover {
					~ label.selectionArea, ~ label.desc {
						color: @HighlightColor;
					}

					~ label.selectionArea {
						position: relative;

						&:not([svg]) {
							border: 2px solid @HighlightColor;
						}

						&:after {
							content: "";
							width: 40px;
							height: 40px;
							background: transparent url("../../../../../default/img/icons/selections/icon_selection_selected.png") 0 0 no-repeat;
						}
					}
				}
			}

			&:disabled {
				~ label.selectionArea, ~ label.desc {
					&, &:hover {
						color: @ButtonInactiveColor;
					}
				}

				~ label.selectionArea:not([svg]) {
					&, &:hover {
						border: 2px solid @ButtonInactiveColor;
					}
				}

				&:checked {
					~ label.selectionArea, ~ label.desc {
						color: @InputFontInactiveColor;
					}

					~ label.selectionArea:not([svg]) {
						border: 2px solid @PrimaryLayoutingColor;
					}
				}
			}
		}

		label {
			&.selectionArea {
				color: @PrimaryFontColor;

				&:not([svg]) {
					background-color: @PrimaryCiColor;
					border: 2px solid @PrimaryCiColor;
				}

				width: 158px;
				height: 108px;
			}

			&.desc {
				display: block;
				font-weight: bold;
				padding-top: 5px;
				max-width: 154px;

				&:hover {
					color: @HoverColor;
				}
			}
		}
	}
}

.ie {
	.selectionGroup .selectionArea > span {
		display: inline-block;
		margin-top: 7px;
	}
}