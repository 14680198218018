@import '../../utilities/lib.less';
div.box {
	&.error , &.notification {
		background-color: transparent;
		margin: 0 0 20px;
		padding: 0;
		header {
			color: @PrimaryBackgroundColor;
			padding: @GuttingDefault 8px;
		}
	}

	&.error {
		color: @ErrorColor;
		header {
			background-color: @ErrorColor;
		}

		&.small {
			border: 0 none;
			margin-left: 160px;
			margin-bottom: 0;
			padding: @GuttingDefault 0;
		}
	}

	&.notification {
		display: none;
	}
}

.tabulationContainer div.box.error {
	margin-bottom: 0;
}

#editMask div.box.error {
	margin-bottom: 0;
	line-height: 16px;
}

.ym-grid div.box.notification {
	padding-left: @GuttingDefault * 2;
}

div.errorContent , div.notificationContent {
	padding: 10px;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
}

div.errorContent {
	border: 1px solid @ErrorColor;
}

div.notificationContent {
	border: 1px solid @SuccessColor;
}

