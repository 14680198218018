@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "./style/variableProvider.less";

.cdk-global-scrollblock bas-root {
	position: relative;
	z-index: 0;
}

.cdk-overlay-dark-backdrop {
	background: rgba(0, 0, 0, 0.7);
}

.cdk-overlay-container {
	z-index: @Index_OverlayContainer;
}

.cdk-overlay-pane {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;

	mat-dialog-container {
		grid-column: 1;
		grid-row: 1;
		max-height: none;
	}

	.mat-dialog-actions {
		margin-bottom: -22px;
	}
}

.mat-dialog-title {
	font-family: inherit;
}

svg.no-transition:not(:hover) {
	.svg-transition {
		display: none;
	}
}

svg.no-transition:hover {
	.svg-hide-transition {
		display: none;
	}
}

svg:not(.no-transition):not(:hover) {
	.svg-transition {
		fill: transparent;
	}
}

//Das Drecksteil von IE kann das nicht. Pech gehabt.
//Das Team hat entschieden, dass IE-NutzerInnen keine sticky Images brauchen.
.stickyImage {
	position: sticky;
	top: 0;
}
