@import '../utilities/lib';
@Icon_Set: url("../../@{Icon_Set_File}");
#breadCrumb {
	margin: 0;
	margin-bottom: 10px;
	padding: 8px;

	a {
		padding: 0;
		text-decoration: none;
		text-shadow: 0 0 0 @Black;

		&:hover {
			text-decoration: none;
		}
	}

	.defaultLink {
		background: none;
	}

	span {		
		text-shadow: none;
	}

	a , span {
		font-size: 12px;
		padding-right: 0;
	}

	> span , > a.defaultLink > span {
		line-height: 14px;
	}

	span , .defaultLink {
		line-height: 14px;
	}
}

.breadCrumbSeparator {
	padding: 0 8px;
	width: 6px;
	background: url("../../img/breadcrumb.png") no-repeat transparent center center;
	margin: 0 @GuttingDefault;
	height: 16px;
}

.toHome {
	float: left;
	width: 20px;
	height: 20px;
	&:hover {
		background-color: @HoverColor;
	}
}

.crumbContainer {
	display: flex;
	flex-wrap: wrap;
	padding-left: 22px;
	margin-top: 1px;
	> * {
		white-space: nowrap;
	}
}

