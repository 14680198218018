@import "../../utilities/lib.less";

.chosen-container-single, .chosen-container-multi {
	.chosen-single, .chosen-choices {
		border: 1px solid @PrimaryLayoutingColor;
		border-radius: 4px;
		background: @PrimaryBackgroundColor;
		box-shadow: @DefaultInputBoxShadow;
		color: @PrimaryFontColor;
		text-align: left;
		font-weight: normal;

		> span {
			min-height: 23px;
			line-height: 21px;
			margin-right: 0;
			padding-top: 1px;
			padding-bottom: 1px;
			padding-left: 2px;
		}

		li.search-field input[type="text"] {
			padding-left: 3px;
			height: 21px;
		}

		li.search-choice {
			span {
				line-height: 17px;
				margin-right: 1px;
			}

			line-height: 17px;
			padding: 0 20px 0 5px;
			margin: 2px 0 0 3px;
			color: @PrimaryLayoutingColor;
			background: @White;
			border-radius: 0;

			&:hover {
				border-color: @HoverColor;
				color: @HoverColor;

				.search-choice-close {
					background-position: @IconChosenClose;
					background-color: @HoverColor;
					border-color: @HoverColor;
				}
			}

			.search-choice-close {
				width: 17px;
				height: 17px;
				top: 0;
				right: 0;
				background: @ButtonColor url("chosen-sprite-bb.png") @IconChosenClose no-repeat;
				border: none;
			}
		}
	}

	.chosen-drop {
		margin-top: 0;
		border-radius: 0;
		box-shadow: none;
		border: 1px solid @PrimaryLayoutingColor;
		min-width: 100%;
		width: 100%;
	}
}

.chosen-container-single .chosen-single {
	padding: 0 0 0 3px;
}

.chosen-container-multi .chosen-choices {
	padding: 0;
}

.chosen-container-multi .chosen-drop {
	border-top: none;
}

.chosen-container-active {
	.chosen-single > span {
		color: @HighlightColor;
	}

	&.chosen-with-drop {
		.chosen-single {
			background-image: none;
			border: 1px solid @PrimaryLayoutingColor;
			border-bottom-color: transparent;

			.result-selected {
				color: @HighlightColor;
			}
		}

		.chosen-drop {
			input {
				border: 1px solid @PrimaryLayoutingColor;
				text-align: left ! important;
			}
		}

		.chosen-results {
			margin: 0 0 0 0;
			padding: 2px 3px;
			color: @PrimaryFontColor;

			li.no-results {
				color: @PrimaryFontColor;
				display: list-item;
				background: @PrimaryBackgroundColor;
			}
		}
	}
}

.chosen-container {
	.chosen-results {
		margin: 0 4px 2px 0;
		padding: 0;

		li {
			padding: 2px 0 2px 2px;
			white-space: nowrap;
			text-align: left;

			&.highlighted {
				background: @PrimaryBackgroundColor;
				color: @HighlightColor;
			}

			&.disabled-result {
				color: @InputFontInactiveColor;
			}

			mark {
				color: inherit;
				background-color: inherit;
				font-weight: bold;
			}
		}
	}
}

select:empty {
	display: block ! important;
	visibility: hidden;
	height: 0;
}

.chosen-container {
	position: relative;
	max-width: 100%;

	.chosen-drop {
		z-index: @Index_Header - 1;
	}
}

#editMask div.select.hasErrors div.chosen-container .chosen-single,
select.error ~ div.chosen-container .chosen-single {
	border: 1px solid @ErrorWidget_ErrorColorBackground;
	box-shadow: 0 0 4px 0 @ErrorWidget_ErrorColorBackground;
	outline: 0;
}

