@import '../../utilities/lib.less';
@Icon_Set: url("../../../@{Icon_Set_File}");
div.panel {
	background-color: @PrimaryBackgroundColor;
	margin: 0 0 10px 0;
	border-radius: @BorderRadiusDefault;
	&.containsErrors {
		border: 1px solid @ErrorWidget_ErrorColorBackground;
		box-shadow: 0 0 4px 0 @ErrorWidget_ErrorColorBackground;
		.panelHeader  {
			.panelLabel label , .counter {
				color: @ErrorWidget_ErrorColorBackground
			}
		}
	}
	&.containsErrors > div.panelContent{
		border: none !important;
	}
}

div.panelHeader {
	border: 3px solid @TertiaryLayoutingColor;
	padding: 11px;
	color: @PrimaryFontColor;
	font-size: 10pt;
	border-radius: @DefaultBorderRadius;
	* {
		cursor: pointer;
		font-size: 15px;
	}

	&[data-toggleable="false"] {:not(.icon):not(.iconContainer) {
			cursor: default;
		}
	}

	&.expand{
		border-radius: @DefaultBorderRadius @DefaultBorderRadius 0 0;
		
	}
	.toggleIcon {
		float: left;
		margin-right: 10px;
	}

	.counter {
		margin-left: @GuttingDefault;
	}

	div.panelContent {
		display: block;
	}
}

div.panelContent {
	padding: 10px;
	background: @PrimaryBackgroundColor;
	border-bottom: 1px solid @SecondaryLayoutingColor;
	border-left: 1px solid @SecondaryLayoutingColor;
	border-right: 1px solid @SecondaryLayoutingColor;
	border-radius: 0 0 @DefaultBorderRadius @DefaultBorderRadius;
	&.searchmask {
		margin-bottom: 35px;
	}
}

div.jstree-default.jstree-focused.treediv {
	border: 1px solid @SecondaryLayoutingColor;
	margin-right: 10px;
	padding: 10px;
	background-color: @PrimaryBackgroundColor;
}

.panel .bas-formBox {
	width: 100%;
}

.toggleAllPanelWrapper .bas-inputButton {
	height: 100%;
	.button {
		& , &:hover , &:active , &:focus {
			position: absolute;
			right: 0;
			margin-right: 0;
			margin-bottom: 0;
		}
	}

	&.inactive {
		& , > .button {
			& , &:focus , &:active , &:link {
				background: @ButtonInactiveColor;
				color: @ButtonFontInactiveColor;
				border-radius: @DefaultBorderRadius;
				cursor: default;
			}
		}
	}
}

