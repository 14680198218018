@import "../utilities/lib.less";
.resultSet {
	background-color: @PrimaryBackgroundColor;
	padding: 10px;
	.resultTemplateInfo , .cmsTeaserContent {
		padding-left: 17px;
	}

	&.superview .resultImage {
		text-align: center;
	}

	.imageList {
		display: none;
	}
}

div.familyData , div.resultSetSection , div.resultInfoFooter {
	padding: @GuttingDefault @GuttingDefault @GuttingDefault 25px;
}

div.resultTemplateDescription {
	padding-left: 5px;
}

div.resultImage {
	a , a:hover {
		border: 1px solid @SecondaryLayoutingColor;
	}
}

a.icon.save_small {
	position: absolute;
	top: @GuttingDefault;
	right: 0;
}

section.addressfamily {
	padding-top: 10px;
	padding-bottom: 20px;
	a {
		display: block;
		margin: @GuttingDefault 0;
	}

	section.addressfamily {
		a img {
			border: 1px solid @SecondaryLayoutingColor;
		}

		a:hover img {
			border-color: @PrimaryCiColor;
		}
	}
}

.orderChangeButtons {
	float: right;
}

.overlay.superView img {
	border: 1px solid @SecondaryLayoutingColor;
}

.resultFormatGroup {
	display: block;
	overflow: hidden;
	> a {
		clear: left;
		float: left;
		margin-bottom: 10px;
		display: inline-block;
		&.addTemplateLink {
			display: inline-block;
			margin-bottom: 0;
		}
	}
}

div.familyData {
	padding: 0 0 20px 0;
	span.shortdescription {
		display: block;
		line-height: inherit;
	}

	.familySpecs {
		margin-top: 22px;
		span {
			display: block
		}

		.cmsTeaser {
			margin-bottom: 27px;
			font-style: italic;
			padding: 0 20px;
		}
	}

	.familyInteractions , .resultTemplateInfo {
		.linkWrapper {
			display: block;
		}
	}

	.familyInteractions {
		border-top: 1px solid @SecondaryLayoutingColor;
		border-bottom: 1px solid @SecondaryLayoutingColor;
		padding: 7px 0;
		a {
			padding: 3px 17px;
		}
	}

	.resultTemplateInfo {
		padding: 0;
		.linkWrapper {
			margin-bottom: 7px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.familyDescription {
		border-top: 1px solid @SecondaryLayoutingColor;
		ul li {
			margin-left: 25px;
		}
	}

	.templates {
		padding-top: 13px;
	}

	.templateMetadata , .familyDescription {
		color: @TertiaryCiColor;
	}

	.templateMetadata {
		line-height: 20px;
		font-size: 12px;
		margin-bottom: 12px;
		> span {
			display: block;
		}
	}

	.familyDescription , .familySpecs {
		padding: 10px 17px;
	}

	.template {
		padding: 17px;
		header.groupSeparator {
			margin: 0 0 10px 0;
			border-bottom: 0;
			font-weight: normal;
			font-size: 17px;
		}
	}

	#detailedit .iconContainer {
		background-position: -21px -100px;
	}

	#copynewslettertemplate , #copycmsfamily {
		.iconContainer {
			background-position: -60px 0;
		}
	}
}

.resultImage a {
	overflow: hidden;
}

.kompakt {
	display: grid;
	padding: 10px 0;

	grid-template-columns: repeat(auto-fill, minmax(@PortletMinWidth, 1fr));
	justify-content: space-between;
	column-gap: @PortletColumnGap;
	row-gap: @PortletRowGap;
	p {
		margin: 0;
	}

	h3 {
		font-size: 12px;
		line-height: 15px;
		text-transform: none;
	}

	.portlet {
		border: 1px solid @TertiaryLayoutingColor;
		min-height: 200px;
		position: relative;

		&:hover {
			background-color: @TertiaryLayoutingColor;
		}

		.resultTemplateType {
			bottom: 15px;
			left: 15px;
			margin: 0;
		}

		.templatePreview {
			height: 150px;
			max-width: 200px;
			& , img {
				float: left;
				border: 1px solid var(--color--layout--tertiary);
			}

			img {
				max-height: 100%;
			}
		}

		.themeTag .iconContainer {
			background-position: -60px 0;
		}

		.localizedTag .iconContainer {
			background-position: -60px 0;
		}
	}
}

.detail {
	background-color: @PrimaryBackgroundColor;
	padding: 10px 0;
	border: none;
	.portlet {
		padding: 30px 0;
		&:first-child {
			padding-top: 0;
		}

		ul li {
			margin-left: 50px;
		}

		a {
			display: inline-block;
		}

		.familyDescription {
			height: 26px;
		}

		.portletInnerContainer {
			display: flex;
			align-items: flex-start;
			.templatePreview {
				width: 210px;
				a , img {
					max-width: 100%;
					&.defaultLink {
						padding: 0;
					}
				}

				a {
					display: block;
					position: relative;
					border-bottom: none;
					line-height: 0;
					& , &:hover {
						border-bottom: none;
					}

					&:hover {
						.hoverEffect {
							display: flex;
							justify-content: center;
							align-items: center;
							overflow: hidden;
						}
					}
				}

				.additionTemplateNote {
					width: 100%;
					height: 100%;
				}
			}

			.familyData {
				width: 80%;
				.familyTemplates .resultSetSection.template .linkWrapper a {
					padding: 2px 0;
				}
			}
		}
	}

	header.familyTitle {
		font-size: 16px;
		line-height: 26px;
		padding: 10px 15px;
		background-color: @TertiaryLayoutingColor;
		.resultTemplateDescription {
			padding-left: 0;
		}

		span {
			font-size: 12px;
			font-weight: normal;
			text-transform: none;
			&.shortDescription {
				font-size: 20px;
				line-height: 23px;
			}
		}

		.tooltipTrigger {
			padding-left: 15px;
		}

		svg.info {
			height: 26px;
			margin-bottom: -8px;
			padding-left: 0;
		}
	}

	p {
		margin: 0;
	}

	.resultSetSection , .familyData {
		padding: 10px;
		padding-top: 0;
	}

	.resultSetSection.active {
		background-color: var(--color--highlight--alpha-10);
		&:only-of-type {
			background: transparent;
		}
	}

	div.inputSeparator {
		display: none;
	}

	.familyData {
		padding-top: 0;
		padding-right: 0;
		padding-left: 10px;
		> p {
			margin-left: 27px;
		}
	}
}

.portlet {
	background-color: @PrimaryBackgroundColor;
	.portletLink {
		display: block;
		width: 100%;
		height: 100%;
		padding: 15px;
		position: relative;
		line-height: 16px;
		& , &:hover {
			border-bottom: none;
		}

		&.inactive {
			cursor: default;
		}
	}

	.templatePreview {
		overflow: hidden;
		border: none;
		img {
			display: block;
		}

		.additionalTemplateNote , .additionalTemplateNoteCms {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			overflow: hidden;
		}

		.portletOverlay {
			width: 100%;
			height: 100%;
			background-color: @PrimaryBackgroundColor;
			opacity: 0.8;
			top: 0;
			left: 0;
			position: absolute;
			&.postproduction {
				display: none;
			}
		}

		.portletNote {
			width: 110%;
			position: absolute;
			top: 50%;
			left: 50%;
			color: @SecondaryCiColor;
			.translate (-50% , -50%);
			text-align: center;
			z-index: 10;
			> span {
				white-space: pre;
				font-family: @DefaultFont;
				font-weight: 500;
				font-style: normal;
				font-size: 20px;
				width: 100%;
				left: -7%;
				background: @PrimaryBackgroundColor;
				border: 3px solid @SecondaryCiColor;
				display: inline-block;
				position: relative;
				padding: 10px;
				.rotate (-15deg);
			}
		}

		.previewIcon {
			background-image: url("../../../../../default/img/loupe.png");
			position: relative;
			top: auto ! important;
			left: auto ! important;
		}
	}

	.wkztag {
		width: 33px;
		position: absolute;
		bottom: -34px;
		right: -31px;
		&_simple {
			text-align: center;
			line-height: 25px;
			font-size: 25px;
			cursor: default;
			color: @HoverColor;
		}
	}

	.interactionPortlet {
		position: absolute;
		top: 15px;
		right: 15px;
		> * {
			display: block;
			margin: 0 auto;
			margin-bottom: 5px;
			width: 25px;
			height: 25px;
			&:last-child {
				margin-bottom: 0;
			}

			&.tooltip {
				display: none;
			}
		}

		.superviewButton {
			cursor: pointer;
		}

		.infoForUser {
			margin: 0 auto;
			display: block;
		}

		.svg_percent {
			fill: @HoverColor;
			transform: scale(1.2);
		}
	}

	.resultTemplateType {
		span {
			display: inline-block;
			margin-left: 5px;
			&:first-child {
				margin-left: 0;
			}
		}
	}

	.familyDescription {
		padding: 10px 5px 5px 0;
		clear: both;
		span {
			line-height: 16px;
		}

		.shortdescription {
			display: block;
		}
	}

	&.grid .lightboxOverlay .actions .icon.edit_small .iconContainer {
		background-position: -21px -100px;
	}
}

.resultTemplateType {
	margin: 10px 17px;
}

.resultTemplateType , .familySpecs span {
	font-size: 12px;
	font-weight: normal;
	color: @TertiaryCiColor;
}

.ribbon {
	span {
		background: @SecondaryCiColor;
		&:before {
			border-left: 3px solid var(--color--ci--secondary--darken-5);
			border-bottom: 3px solid var(--color--ci--secondary--darken-5);
		}
	}

	&:after {
		border-left: 3px solid var(--color--ci--secondary--darken-5);
	}
}
