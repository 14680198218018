 @import '../../utilities/lib.less';
 @IconDimensions: 20px;

 .marketing-planer-kalender {
 	margin-left: 10px;
 	margin-right: 25px;

 	.cal_interaction_container {
 		.bas-inputButton {
 			.dummy.button.today {
 				margin-bottom: 0;
 			}
 		}
 	}

 	&.sticky .cal_header_sticky {
 		background-color: @PrimaryBackgroundColor;
 		border-bottom: 1px solid grey;
 	}

 	.cal_header {
 		.cal_row.header {
 			&.detail {
 				padding: 0;

 				.prev,
 				.next {
 					width: @IconDimensions;
 					height: @IconDimensions;
 					margin-top: 2px;
 				}

 				.next {
 					right: 0;
 				}

 				.cal_cell {
 					padding: 0;
 				}
 			}

 			&.rough {
 				font-weight: bold;
 				font-size: 13px;

 				.cal_cell {
 					border-left-color: @SecondaryLayoutingColor;
 				}
 			}
 		}
 	}

 	.cal_today .cal_today_font {
 		font-size: 12px;
 		color: @SecondaryCiColor;
 	}

 	.cal_today_indicator {
 		border-right-color: @SecondaryCiColor;
 	}

 	.calender {
 		.expand.icon.defaultLink {

 			&.openAll,
 			&.closeAll {
 				display: block;
 				outline: none;
 			}
 		}
 	}

 	.calender .cal_row {

 		.werbemittel,
 		.name {
 			&.hover {
 				background: @SecondaryLayoutingColor;
 			}
 		}

 		.cal_campaign {
 			border-color: @SecondaryLayoutingColor;

 			>div {
 				border-color: @SecondaryLayoutingColor;
 			}
 		}

 		&.first .cal_cell {
 			border-bottom-color: @SecondaryLayoutingColor;
 		}

 		.cal_cell {
 			border-color: @SecondaryLayoutingColor;
 		}
 	}
 }

 .marketing-planer-kalender .calender .expand.icon {

 	&.openAll,
 	&.closeAll {
 		margin-left: @ExpandIconOffset;
 	}
 }

 .exportExcel {

 	&.normal,
 	&.own {
 		margin-left: 5px;
 	}
 }

 /*
* HERE BE DRAGONS
*/
 .cal_legende,
 .marketing-planer-kalender {
 	&:not(.cannot_follow) {

 		.wm,
 		.kampagne {
 			&.normal:not(.teilgenommen) {
 				background: @NormalNotFollowedGanttBackground;
 			}
 		}
 	}

 	.kampagne {
 		&.normal {
 			background-color: @NormalGanttBackground;
 		}

 		&.own {
 			border-style: solid;
 			border-color: @GanttBorderColor;
 			background-color: @OwnGanttBackground;
 		}
 	}

 	.wm {
 		&.werbezeitraum {
 			border-color: black;
 			background-color: transparent !important;
 			border-width: 2px;
 		}

 		&.normal {
 			background-color: @WmGantBackground;
 		}

 		&.national {
 			background-color: @NationalGanttBackground;
 		}

 		&.sperrfrist {
 			background-color: @SperrfristGanttBackground;
 		}

 		&.own {
 			border-style: solid;
 			border-color: @GanttBorderColor;
 			background-color: @OwnGanttBackground;
 		}
 	}

 	.erscheinungstermin {
 		background-color: @Yellow;
 	}
 }