 @import '../../utilities/lib.less';
.floatingPageHeader {
	background-color: @PrimaryBackgroundColor;
	.ym-gl{
		border-right-color: @TertiaryLayoutingColor;
	}
	
	.ym-gr {
		input[type=text].deeplink {
			width: @ColsInput2;
		}
	}
	
	.gruppenbudget{
		background: @TertiaryLayoutingColor;
	}

	.negative-groupmember-budget {
		border: 1px solid @ErrorColor;
	}
}