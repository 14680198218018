@import '../../utilities/lib.less';
.ui-widget-content {
	a {
		color: @PrimaryFontColor;
	}

	li.ui-state-focus a {
		color: @White;
		font-weight: initial;
	}
}

ul.ui-autocomplete {
	z-index: @Index_AutoComplete ! important;
	li[id ^= "ui-id"] {
		font-size: 11px;
		&.ui-state-hover , &.ui-state-focus {
			background-image: none;
			background-color: @ButtonColor;
			border: 0 none;
			color: @ButtonFontColor;
			font-weight: normal;
		}
	}
}
