@import '../../utilities/lib.less';
.flex {
	&.horizontal {
		> span {
			line-height: 21px;
		}

		.itemBox {
			margin-bottom: 0;
			padding-right: 5px;
		}
	}

	&.cols1-3 .itemBox:last-child {
		max-width: 544px;
	}
	
	&.vertical {
		input[type=text] , input[type=password], input[type=file] , select:not([size]) , textarea, .cke, .inlineckeditor {
			margin-bottom: 10px;
		}
		
		input.datepicker {
			float:none;
			
			+ .ui-datepicker-trigger {
				position: absolute;
			}
		}
		
		.item.horizontal {
			display: flex;
		}
	}
}

.noLabel .flex.horizontal > .itemBox {
	max-width: 1080px;
}

