@import '../utilities/lib.less';

div.tabulation {
	display: table;
	border-collapse: collapse;
	width: 100%;

	div.col {
		color: @PrimaryFontColor;
		// font-size: 11px;
		font-weight: bold;
		// line-height: 13px;
		border: 1px solid @SecondaryLayoutingColor;
		padding: 4px 0 7px 7px;

		img {
			background: @PrimaryBackgroundColor;
		}
	}

	div.row {
		display: table-row;
	}

	div.row div.col {
		background: none;
		font-weight: normal;
		vertical-align: top;
	}
}

table.tabulation {
	line-height: 13px;
	color: @PrimaryFontColor;
	border: 1px solid @SecondaryLayoutingColor;
	border-right: 0;
	border-collapse: separate;
	border-radius: @DefaultBorderRadius;

	tr {
		border-top: solid 1px @SecondaryLayoutingColor;
		border-bottom: solid 1px @SecondaryLayoutingColor;

		&.even,
		&.odd {
			&.rowGroup {

				&,
				td {
					border-bottom: 0 none;
				}

				&.last {

					&,
					td {
						border-bottom: 1px solid @SecondaryLayoutingColor;
					}

					&:last-of-type {

						&,
						td {
							border-bottom: 0 none;
						}
					}
				}

				td {
					border-top: 0 none;
				}
			}

			td.col.error {
				color: @ErrorColor;
				background: @SecondaryLayoutingColor;
				border: 1px solid @ErrorColor;
			}

			td.errorcol {
				padding-top: 20px;
			}

		}

		&.footer td {
			>* {
				padding: 0;
			}
		}

		&.header {
			height: 25px;

			&,
			&:hover {
				td {
					background: @PrimaryLayoutingColor;
					color: @ButtonFontColor;
				}
			}

			td {
				&.last {
					border-right: 0;
				}
			}

			td.col>* {
				vertical-align: top;
			}

			>td {
				>a.defaultLink {
					color: @ButtonFontColor;

					&:hover {
						color: @PrimaryCiColor;
					}
				}

				>.infotext {
					color: @ButtonFontColor;
					opacity: 0.5;
				}
			}
		}

		&.odd,
		&:nth-child(odd) {
			background-color: @PrimaryBackgroundColor;
		}

		&.even,
		&:nth-child(even) {
			background-color: @PrimaryBackgroundColor;
		}

		&.headerRow {
			background-color: @SecondaryLayoutingColor;
		}

		td {
			padding: @GuttingDefault;

			&.priceColumn {
				vertical-align: bottom;
			}

			.tooltipTrigger.infoIcon {
				margin-top: 0;
			}

			>a {
				display: inline;
			}
		}
	}

	td {
		border-right: solid 1px @SecondaryLayoutingColor;

		&.odd {
			background-color: @PrimaryBackgroundColor;
		}

		a.icon.defaultLink {
			padding-bottom: 0;
		}

		.item.horizontal>.itemBox:last-of-type {
			padding-right: 0;
		}

		label.requiredMarker {
			margin-left: 15px;
		}
	}

	.archiveDateCol {
		width: 90px;
		min-width: 90px;
	}

	.tooltipTrigger.infoIcon {
		margin-top: 0;
	}

	&.blockConfiguration {
		input.freetextplaceholder {
			width: 100%;
		}
	}
}

table.tabulation tr.header,
table.tabulation tr.header td,
tbody tr.header:hover td,
tbody tr.header:hover td {
	height: auto;
	padding: @GuttingDefault;
	min-width: 40px + 2 * @GuttingDefault;

	.smallButtonColumn {
		min-width: 24px + 2 * @GuttingDefault;
	}
}

table.tabulation,
tbody {

	tr.header.sortRow td,
	tr.header.sortRow:hover td {
		background-color: @TertiaryLayoutingColor;
		border-top: 1px solid @PrimaryBackgroundColor;
	}

	tr.footer td,
	tr.footer:hover td {
		background-color: @TertiaryLayoutingColor;

		.button {
			margin-bottom: 0;
		}
	}
}

table tr.header td.col span {
	padding: 0 0 0 0;
	font-weight: bold;
}

td.col>.icon {
	float: left;
	margin-right: 2px;

	&:last-child {
		margin-right: 0;
	}
}

.sortIcons {
	text-align: center;
	margin-top: @GuttingDefault;
	line-height: 0;

	>a {
		margin: 0;
		padding: 0;
	}
}

.tabulation tbody tr:hover {

	th,
	td {
		background-color: inherit;
	}

	.tabulation {
		tr {
			&.header {

				&,
				&:hover {
					td {
						background: @PrimaryLayoutingColor;
						color: @ButtonFontColor;
					}
				}
			}

			&.footer {

				&,
				&:hover {
					td {
						background-color: @TertiaryLayoutingColor;
					}
				}
			}
		}
	}
}

.header.row {
	>.col>.defaultLink {
		padding-bottom: 0;
	}
}

.item.horizontal.contains-input {

	.tabulation,
	.tabulation tr,
	.tabulation td {
		border: 0 none;
	}

	.tabulation .odd {
		background-color: @TertiaryLayoutingColor;
	}
}

tbody {
	td {
		border: 0 none;
		border-top: 1px solid @TertiaryLayoutingColor;
	}

	.header td {
		border-top: 0;
	}
}

.cmsPictureWithFooter {
	tbody {
		td.picture-footer {
			.bas-inputButton {
				a {
					margin-top: 16px;
				}
			}

		}

	}
}

div.tabulationContainer {
	&.scrollable {
		border-bottom: 1px solid @TertiaryLayoutingColor;
		border-left: 1px solid @TertiaryLayoutingColor;
		border-right: 1px solid @TertiaryLayoutingColor;

		table.tabulation {
			border-left: none;
			border-right: none;
		}
	}

	&.half {
		width: 50%;
	}

	~div.footer {
		padding: 5px;
		border: solid 1px @TertiaryLayoutingColor;
		background: @TertiaryLayoutingColor;
		border-top: 0;

		.button {
			margin-bottom: 0;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}