@import '../utilities/lib.less';
.cke_dialog_body {
	.placeholderListWrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-height: 400px;
		margin-top: 5px;
		overflow-y: auto;
		border: 1px solid @HighlightColor;
		padding: 4px;
		width: 592px;
		.placeholderElement {
			border: 1px solid @TertiaryLayoutingColor;
			width: 280px;
			margin-top: 5px;
			margin-right: 2px;
			box-sizing: border-box;
			padding: 4px;
			&:hover {
				background: @TertiaryLayoutingColor;
				cursor: pointer;
				> * {
					cursor: pointer;
				}
			}

			&.selected {
				border-color: @HighlightColor;
				> * {
					color: @HighlightColor
				}
			}

			.placeholderName {
				font-weight: bold;
				margin-bottom: 2px;
			}

			.placeholderDescription {
				white-space: normal;
			}

			.placeholderExample {
				white-space: normal
			}
		}
	}

	.selectedTemplatePlaceholderWrapper {
		max-width: 600px;
		white-space: normal;
		&.hasContent {
			max-height: 100px;
			overflow-y: auto;
			display: block;
			> span.labelSelectionEmpty {
				display: none;
			}
		}

		> span {
			display: block;
			margin-bottom: 5px;
			&:first-child {
				margin-top: 5px;
				padding-top: 5px;
			}
		}

		.selectedTemplatePlaceholderElement {
			display: inline-block;
			margin: 0 3px 3px 0;
			background: @ButtonColor;
			color: @ButtonFontColor;
			padding: 1px 2px;
			&.invisible {
				visibility: hidden;
			}
		}
	}

	.templatePlaceholderDescriptionLabel {
		max-width: 600px;
		white-space: normal;
		display: block;
	}
}

