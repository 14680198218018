@import '../utilities/lib.less';
#searchMask {
	.panel {
		&:not(.smartFilter) {
			input[type=text] , select {
				width: 547px;
			}

			.chosen-search > input[type=text] {
				width: 100%;
			}

			textarea , input , select {
				&.cols1 {
					width: 182px;
					&.hasDatepicker {
						width: @ColsInput1;
					}
				}
			}

			.bas-formBox {
				width: 100%;
			}
		}

		&.smartFilter {
			.panelContent {
				padding: 0;
				.filter {
					&:not(:first-of-type):before {
						content: '';
						height: 90%;
						width: 1px;
						position: absolute;
						left: -10px;
						background-color: @TertiaryLayoutingColor
					}

					.filterGroup.separator {
						border-top: 1px solid @TertiaryLayoutingColor;
					}

					&[data-active] , &:hover {
						box-shadow: 0 0 4px 0 @HighlightColor;
					}

					a[data-toggle-target] {
						.iconContainer , span {
							float: left;
						}

						span {
							line-height: 18px;
						}
					}
				}
			}
		}
	}

	.bas-inputButton:first-child {
		margin-left: @Cols;
	}

	.field.noLabel .bas-inputButton:first-child {
		margin-left: 0;
	}

	.itemBox .bas-inputButton {
		margin-left: 0;
	}
}

.bas-filterComponent {
	padding: 5px 0 5px 10px;
	margin-bottom: @GuttingDefault;
	&.forceNewLine {
		border-top: 1px solid @TertiaryLayoutingColor;
		margin-top: 10px;
		padding-top: 10px;
	}

	label {
		width: @Cols;
		min-width: @Cols;
	}

	&.checkboxContainer {
		label , .bas-checkboxSubContainer {
			display: table-cell ! important;
		}
	}

	.bas-checkboxSubContainer {
		.bas-inputCheck {
			padding-right: 10px;
			width: auto;
			line-height: 20px;
			> input.checkbox , > input.radio , > input[type="checkbox"] , > input[type="radio"] {
				margin-right: @GuttingDefault;
			}

			> * {
				display: inline-block ! important;
			}
		}
	}

	.forceNewLine .searchButtonPosition .button {
		margin-bottom: 0;
	}

	label , .bas-inputCheck , .bas-inputSelect , .bas-inputText {
		display: inline-block ! important;
		margin: 0 0 0 0;
		vertical-align: top;
	}
}

