@import '../utilities/lib.less';

.ym-vlist {
	ul {
		border: 0;
	}

	li ul li {
		a , strong , span {
			padding-left: inherit;
			width: inherit;
		}
	}
}

