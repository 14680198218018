@import 'variab.less';

.iconContainerBehaviour(){
	width: 20px;
	height: 20px;
	display: inline-block;
	margin-right: @GuttingDefault;
	background-repeat: no-repeat;
	background-color: @ButtonColor;
	background-image: @Icon_Set;
	cursor: pointer;
	.transition(background-color @DefaultButtonAnimation);
}

.commonButtonStyles () {
    padding: 4px 8px;
    display: inline-block;
    cursor: pointer;
    margin-right: 10px;
    line-height: 20px;
    width: 166px;
    text-decoration: none;
    text-align: center;
    font-size: 100%;
    margin-bottom: 10px;
    border: none;

    .transition(@DefaultButtonAnimation);
}

.commonGhostButtonStyles() {
	line-height: 16px;
	background-color: transparent;
}

.defaultButton (@local_Color_Button: @ButtonColor , @local_Color_FontButton: @ButtonFontColor , @local_Color_Button_Hover: @HoverColor , @local_Color_Button_Inactive: @ButtonInactiveColor , @local_Color_Button_InactiveFont: @ButtonFontInactiveColor) {
	.commonButtonStyles ();
	background-color: @local_Color_Button;
	color: @local_Color_FontButton;
	&:active , &:hover , &:focus {
		background-color: @local_Color_Button_Hover;
		color: @local_Color_FontButton;
		text-decoration: none;
	}

	&.inactive {
		background: @local_Color_Button_Inactive;
		color: @local_Color_Button_InactiveFont;
		border: 1px solid @local_Color_Button_InactiveFont;
		cursor: default;
	}
}

.highlightButton (@local_Color_Button_Highlight: @HighlightColor , @local_Color_Button_Hover: @HoverColor , @local_Color_Button_Inactive: @ButtonInactiveColor, @local_Color_Button_InactiveFont: @ButtonFontInactiveColor) {
	.commonButtonStyles ();
	color: @ButtonFontColor;
	background-color: @local_Color_Button_Highlight;
	&:hover , &:active , &:focus {
		color: @ButtonFontColor;
		background-color: @local_Color_Button_Hover;
	}

	&.inactive {
		color: @local_Color_Button_InactiveFont;
		background-color: @local_Color_Button_Inactive;
		cursor: default;
	}
}

.defaultGhostButton (@local_Color_Button: @ButtonColor , @local_Color_Button_Hover: @HoverColor , @local_Color_Button_Inactive: @ButtonInactiveColor, @local_Color_Button_Font: @ButtonFontColor) {
	.commonButtonStyles ();
	.commonGhostButtonStyles();
	border: 2px solid @local_Color_Button;
	color: @local_Color_Button;
	&:hover , &:active , &:focus {
		background-color: @local_Color_Button_Hover;
		border-color: @local_Color_Button_Hover;
		color: @local_Color_Button_Font;
	}

	&.inactive {
		background-color: transparent;
		border-color: @local_Color_Button_Inactive;
		color: @local_Color_Button_Inactive;
		cursor: default;
	}
}

.highlightGhostButton (@local_Color_Button: @HighlightColor , @local_Color_Button_Hover: @HoverColor , @local_Color_Button_Inactive: @ButtonInactiveColor, @local_Color_Button_Font: @ButtonFontColor) {
	.commonButtonStyles ();
	.commonGhostButtonStyles();
	border: 2px solid @local_Color_Button;
	color: @local_Color_Button;
	&:hover , &:active , &:focus {
		background-color: @local_Color_Button_Hover;
		border-color: @local_Color_Button_Hover;
		color: @local_Color_Button_Font;
	}

	&.inactive {
		background-color: transparent;
		border-color: @local_Color_Button_Inactive;
		color: @local_Color_Button_Inactive;
		cursor: default;
	}
}
