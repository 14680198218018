@import '../../utilities/lib.less';
.cmsTeaserContent {
	margin-top: 10px;
	padding: @GuttingDefault @GuttingDefault 4px 17px;
	line-height: 16px;
}

table.fourPictureRow{
	margin-left: auto;
	margin-right: auto;
	.pictureRow{
		.centerImage{
			margin: auto;
			display: flex;
			justify-content: center;
		}
		img{
			width: auto;
		}	
	}
}

.cms-placeholder-withlabel-maxwith {
	max-width: @ColsInput5;
}

.cms-container {
	margin-bottom: 10px;
	line-height: normal;
	> h2 {
		background-color: @TertiaryLayoutingColor;
		margin: 0;
		padding: 10px;
	}

	ul {
		padding-left: 15px;
	}

	ol {
		padding-left: 20px;
	}

	.image-container{
		display: grid;
		img{
			justify-self: center;
		}
	}

	img {
		@schutzraum: 3px;
		margin: @schutzraum;
		max-width: calc(100% - @schutzraum * 2);
	
	}
	a{
		text-decoration: underline;
	}
}

#text-and-links {
	.two-columns-left {
		padding: 10px 20px 10px 0;
	}

	.two-columns-right {
		padding: 10px 20px 10px 0;
		table.tabulation {
			border-right: 1px solid @SecondaryLayoutingColor;
			tr {
				border: 0;
				td {
					border: 0;
				}
			}
		}
	}
}

