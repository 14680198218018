@import '../utilities/lib.less';
.monthpicker, .dayspicker{
	.monthpicker-calendar, .dayspicker-calendar{
		border-color: @Black;
		border-radius:@DefaultBorderRadius;
		.monthpicker-body, .dayspicker-body{
			.month-cell, .day-cell{
				border:solid 1px transparent;
				color: @PrimaryFontColor;
				&.disabled{
					color: @LightGrey !important;
				}
				&:hover:not(.disabled).selected-month, &:hover:not(.disabled).selected-day{
					color: @PrimaryFontColor;
					border: solid 1px @SecondaryCiColor;
					background: transparent;
				}
				&.selected-month, &.selected-day{
					background-color: @SecondaryCiColor;
					color: @SecondaryFontColor;
				}
				&:hover:not(.disabled){
					border: solid 1px @PrimaryCiColor;
					color: @PrimaryFontColor;
				}
			}
		}
	}
}