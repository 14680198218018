@import '../utilities/lib.less';
.reportList {
	padding-left: 10px;
	.reportItem {
		&:hover {
			background-color: @TertiaryLayoutingColor;
		}

		.mainRow {
			.favIcon {
				background-image: url("../../img/icons/star_icon_sprite.png");
			}

			.toggleTrigger {
				margin-top: 3px;
				height: 23px;
				margin-right: 3px;
			}
		}
	}
}

