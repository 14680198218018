@import '../../utilities/lib.less';
span {
	line-height: 22px;
	&.subtitle_normal {
		font-size: 16px;
		color: @PrimaryFontColor;
		display: inline-block;
		margin: @GuttingDefault 0;
	}

	&.infotext_bold {
		color: @PrimaryFontColor;
	}
}

.bas-inputArea > span {
	line-height: 16px;
}

.textstriked {
	text-decoration: line-through;
	color: @DefaultRed;
}

.inactive {
	color: @InputFontInactiveColor;
}

.admin .inactive {
	color: @TertiaryCiColor;
}

.contentContainer h1 {
	font-size: 30px;
	margin-top: 24px;
	margin-bottom: 24px;
}

.groupSeparator {
	margin-left: 10px;
}

label {
	line-height: 18px;
}

.quote {
	font-style: italic;
}

.word-break {
	word-break: break-all;
}

.hint {
	&.inText {
		margin-top: 5px;
	}

	&.tooltipTrigger.infoIcon {
		margin: 0 0 0 @GuttingDefault;
		vertical-align: top;
	}
}

.ckeditorImportant {
	color: @PrimaryCiColor;
}

.defaultText {
	color: @PrimaryFontColor;
}