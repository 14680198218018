@import '../utilities/lib.less';
.img-button.detail {
	border-color: @TertiaryLayoutingColor;
	&:hover , &.selected {
		border-color: @HoverColor;
		.preview img {
			background-color: @HoverColor;
		}

		.description {
			color: @HoverColor;
		}
	}

	.preview img {
		background-color: @ButtonColor;
		border-color: @TertiaryLayoutingColor;
	}

	.description {
		color: @ButtonColor;
	}
}