@import '../../utilities/lib';
@Icon_Slider: url("../../../@{Icon_Set_Slider}");
.camera_pag {
	position: absolute;
	left: 110px;
	top: -10px;
}

.thumb_arrow {
	display: none ! important;
}

.camera_next > span , .camera_prev > span , .camera_commands > .camera_stop , .camera_commands > .camera_play {
	background-image: @Icon_Slider;
	background-repeat: no-repeat;
	background-color: @ButtonColor;
	height: 40px;
	width: 40px;
	border: 5px solid @PrimaryBackgroundColor;
}

.camera_prevThumbs , .camera_nextThumbs , .camera_prev , .camera_next , .camera_commands , .camera_thumbs_cont {
	background: none repeat scroll 0 0 transparent;
}

.camera_prev {
	> span {
		left: 0;
		background-position: -60px 0;
		border-radius: 0 5px 5px 0;
		&:hover {
			background-color: @HoverColor;
		}
	}
}

.camera_next {
	> span {
		right: 0;
		background-position: -90px 0;
		border-radius: 5px 0  0 5px;
		&:hover {
			background-color: @HoverColor;
		}
	}
}

.camera_caption {
	bottom: 13%;
	width: auto;
	color: @PrimaryFontColor;
	background: @TertiaryLayoutingColor;
	-moz-border-radius: 0 5px 5px 0;
	border-radius: 0 5px 5px 0;
	font-size: 18px;
	line-height: 24px;
	height: 42px;
	> div {
		background: transparent;
	}
}

.camera_loader {
	background: rgba(255, 255, 255, 0.9) url("../../../img/camera-loader.gif") no-repeat center;
}

.camera_pie > canvas {
	padding: @GuttingDefault @GuttingDefault 0 0;
}

.camera_wrap .camera_pag .camera_pag_ul li {
	height: 11px;
	width: 11px;
	-moz-border-radius: @GuttingDefault;
	-webkit-border-radius: @GuttingDefault;
	border-radius: @BorderRadiusDefault;
	background: @PrimaryBackgroundColor;
	border: 2px solid @ButtonColor;
	&:hover, &.cameracurrent {
		border-color: @PrimaryLayoutingColor;
	}
	> span {
		display: none;
	}
}

.camera_pag_ul li img {
	height: auto;
}

