@import '../utilities/lib.less';
#notification_selected .circle {
	fill: @PrimaryBackgroundColor;
}

#meta .svg {
	&-contact , &-logout , &-shopping-cart , &-user {
		svg {
			height: 25px;
			width: 25px;
			fill: @PrimaryCiColor;
			margin: 0;
			padding: 0;
			line-height: normal;
		}
	}

	&-user {
		svg {
			.transition (@DefaultButtonAnimation);
			width: 28px;
			height: 25px;
		}

		&:hover svg {
			fill: @HoverColor;
		}

		&.inPreferences {
			& , &:hover {
				svg {
					fill: @HoverColor;
				}
			}
		}
	}

	&-contact {
		margin-top: 2px;
		svg {
			height: 22px;
			width: 22px;
			.contact- {
				&inner , &border {
					.transition (@DefaultButtonAnimation);
				}
			}
		}
	}

	&-logout {
		margin-top: 3px;
		svg {
			height: 20px;
			width: 20px;
		}

		.logout {
			.logout {
				&-inner , &-border {
					.transition (@DefaultButtonAnimation);
					fill: @PrimaryCiColor;
				}
			}
		}
	}

	&-contact , &-logout {
		.contact , .logout {
			&-inner {
				fill: #fff;
			}
		}

		&:hover , &.inContact {
			.logout , .contact {
				&-inner , &-border {
					fill: @HoverColor;
				}
			}
		}
	}

	&-shopping-cart {
		svg {
			height: 23px;
			width: 24px;
			.shopping-cart- {
				&border , &wheel , &inner {
					.transition (@DefaultButtonAnimation);
				}
			}
		}

		.shopping-cart-inner {
			fill: #fff;
		}

		&.inBasket , &:hover {
			.shopping-cart {
				&-border , &-wheel {
					fill: @HoverColor;
				}
			}
		}

		&.isFilled {
			.shopping-cart-inner {
				fill: @PrimaryCiColor;
			}

			&:hover , &.inBasket {
				fill: @HoverColor;
				.shopping-cart {
					&-inner , &-border , &-wheel {
						fill: @HoverColor;
					}
				}
			}
		}
	}
}

svg {
	&.wkztag , &.svg_scalable_template {
		path {
			fill: @HoverColor;
		}

		circle , line {
			fill: #fff;
		}

		.percent {
			path , line {
				fill: none;
				stroke: @PrimaryBackgroundColor;
				stroke-width: 6;
			}

			line {
				stroke-linecap: round;
			}
		}
	}

	&.svg_scalable_template {
		.scale (1.3);
		&.firefox {
			padding-right: 0;
		}
	}

	&.preview_lupe {
		path {
			.transition (@DefaultButtonAnimation);
			fill: @PrimaryCiColor;
		}

		&:hover path {
			fill: @HoverColor;
		}
	}

	&.info {
		width: 15px;
		path {
			fill: none;
			stroke: @HoverColor;
			stroke-width: 5;
			stroke-miterlimit: 10;
		}
	}

	&.localized , &.theme {
		width: 20px;
		height: 20px;
		path {
			fill: @TertiaryCiColor;
		}
	}

	&[class ^= "startpage"] {
		fill: @SecondaryCiColor;
		height: 70px;
		.inner {
			fill: @PrimaryBackgroundColor;
		}
	}

	&.welcome {
		height: 70px;
		path {
			fill: @MediumGrey;
		}
	}

	&#mp_calendar , &#changed_by_user, &#home {
		width: 25px;
		height: 25px;
		rect {
			width: inherit;
			height: inherit;
		}
		.line , .arrow {
			fill: none;
			stroke-miterlimit: 10;
		}
	}

	&#mp_calendar {
		.line {
			stroke-width: 1.5px;
		}

		.cross {
			display: none;
		}
	}

	&#changed_by_user {
		stroke: @PrimaryCiColor;
		.line , .arrow {
			stroke-width: 2;
			stroke-linecap: round;
		}

		.arrow {
			fill: @PrimaryCiColor;
			stroke-linejoin: round;
		}
	}
	
	&#home {
		margin-top: -5px;
		margin-left: -5px;
		path {
			fill: none;
			stroke: @PrimaryCiColor;
		}
	}
	
	&#upload-image, &#default-image, &#db-image {
		.stroke{
			fill:none;
			stroke:@PrimaryCiColor;
			stroke-miterlimit:10;
		}
		
		&:hover {
			.stroke {
				stroke:@HoverColor;
			}
		}
	}
	
	&#no-image {
		.stroke{
			fill:none;
			stroke:@PrimaryCiColor;
			stroke-miterlimit:10;
		}
		.picture{
			fill:none;
			stroke:@MediumGrey;
			stroke-miterlimit:10;
		}
		.circle{
			fill:@PrimaryBackgroundColor;
			stroke:@PrimaryCiColor;
			stroke-miterlimit:10;
		}
		&:hover {
			.stroke, .circle{
				stroke:@HoverColor;
			}
		}
	}
}

.containerTypeImage svg[class ^= "startpage"] {
	height: 180px;
}

.tooltipTrigger svg.info {
	width: 18px;
	padding-left: 3px;
}

.optionTrigger {
	svg.preferences , svg.preferences_scheduled {
		path {
			.transition (@DefaultButtonAnimation);
			fill: @PrimaryCiColor;
		}

		&:hover path {
			fill: @HoverColor;
		}

		.blocker {
			fill: @PrimaryBackgroundColor;
		}
	}
}

.optionerror .optionTrigger svg.preferences_scheduled path {
	fill: @ErrorColor;
}

.bas-inputCheck {
	input[type=checkbox][checked] + label svg#mp_calendar {
		.plus {
			display: none;
		}

		.cross {
			display: block;
		}
	}
}
