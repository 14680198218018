@import '../../utilities/lib.less';
div.boxGrid {
	.ym-gl div.ym-gbox {
		margin-right: 4px;
	}

	.ym-gr div.ym-gbox {
		margin-left: 3px;
	}

	.jsTreeControls {
		margin-bottom: @GuttingDefault;
	}

	&.jsTreeControls {
		.box {
			padding-left: 0;
			padding-top: 0;
			padding-right: 0;
			a , div.inactive {
				padding: 0;
				padding-left: 0 ! important;
			}
			a {
				margin-bottom: 0;
			}
			div.inactive {
				margin-bottom: 3px;
			}
		}

		.ym-gr {
			padding-left: 0;
		}

		.ym-gl {
			padding: 0;
		}

		a.icon.moveLeft , div.icon.move_left_inactive , a.icon.moveRight , div.icon.move_right_inactive {
			margin-top: 0;
		}

		.boxRow {
			margin-bottom: 0;
		}
	}
}

div.box {
	padding: @GuttingDefault;
}

div.boxRow {
	display: table;
	margin-bottom: 7px;
	&.last {
		margin-bottom: 0;
	}
}

div.boxGrid.jsTreeControls a.icon.moveLeft , div.boxGrid.jsTreeControls div.icon.move_left_inactive {
	margin-right: 7px;
}

div.boxGrid.jsTreeControls a.icon.moveRight , div.boxGrid.jsTreeControls div.icon.move_right_inactive {
	margin-left: 6px;
}

div.middle a.controls.moveLeft {
	margin-right: 0;
	float: none;
}
div.middle a.controls.moveRight {
	margin-left: 0;
}
div.ym-gbox.middle {
	text-align: center;
	padding-top: 120px;
}

