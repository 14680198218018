@import '../../utilities/lib.less';
@Icon_Set: url("../../../@{Icon_Set_File}");
#searchfield {
	border: 1px solid @PrimaryLayoutingColor;
	color: @PrimaryFontColor;
	font-family: @DefaultFont;
	text-shadow: 0 0 0 transparent;
	font-size: 11px;
	border-radius: 1px;
	width: 157px;
	margin-top: 2px;
	height: 22px;
	padding: 0 0 0 25px;
	margin-bottom: 0;
	&:hover , &:focus {
		border-color: @PrimaryCiColor;
	}
}

#searchfieldTrigger {
	background-repeat: no-repeat;
	background-color: @ButtonColor;
	cursor: pointer;
	&.icon.search {
		display: inline-block;
		border-radius: 1px;
		position: absolute;
		top: 3px;
		left: 6px;
		cursor: pointer;
		.iconContainer {
			background-position: @IconShow;
		}

		&:hover .iconContainer {
			background-color: @HoverColor;
		}
	}
}

