@import '../../utilities/lib.less';
@Icons_Startpage: url("../../../@{Icon_Set_Infopage}");

#infoPageContents {
	width: 100%;
	position: relative;
	font-family: @DefaultFont;
	height: 502px;
	min-height: 502px;
	max-height: 502px;
	overflow: hidden;

	div.container {
		border: 1px solid @SecondaryLayoutingColor  !important;
		width: 100%;

		div.containerItems {
			width: calc(100% - 300px);
		}

		&.currentTemplate {
			border: 0;
		}

		div.containerGroup h2 span.title {
			color: @PrimaryFontColor;
		}
	}

	div div.containerItems {
		float: right;
		width: 585px;

		.field {
			padding-left: 0;
		}
	}

	.customScrollBar {
		height: 464px;
		width: 100%;
		overflow-y: scroll;
		overflow-x: hidden;

		.jspVerticalBar {
			.jspTrack {
				width: 16px;
			}
		}
	}

	.inputSeparator {
		background-color: @SecondaryLayoutingColor;
	}

	.scrollpane {
		height: 440px;
		width: @ColsInput6;
	}

	>div {
		position: relative;
	}

	.containerContent {
		height: 464px;
		padding: 0 0 10px;

		.itemContent {
			>span {
				display: inline-block;
				padding: @GuttingDefault 0 15px;
				color: @PrimaryFontColor;
			}

			ol,
			ul {
				padding-left: 25px;
			}
		}
	}

	footer {
		text-transform: none;
		padding: 8px 10px;
		box-sizing: content-box;

		a {
			margin-right: 15px;
			padding-bottom: 0;
			line-height: 18px;
		}
	}

	.currentTemplate {
		footer {
			background-color: #FFFFFF;
			opacity: 0.8;
			margin-top: -34px;
		}
	}

	h2 {
		color: @PrimaryFontColor;

		.date {
			font-size: 12px;
			font-weight: bold;
			margin-bottom: 3px;
		}

		.title {
			display: block;
			margin: 2px 0 10px;
			font-size: 15px;
			font-weight: bold;
		}
	}
}

div.container {
	div.containerContent {
		height: 409px;
	}

	div.containerGroup {
		margin: 20px 22px @GuttingDefault 8px;

		h2 {
			margin: 0 0 4px 0;

			span {
				display: block;
			}
		}

		&.alreadyRead {

			span.title,
			span.date {
				font-weight: normal;
				font-style: italic;
			}

			.itemContent {
				font-style: italic;
			}
		}

		.bas-formSection .bas-formSection {
			padding: 0;
		}
	}

	footer {
		padding: @GuttingDefault @GuttingDefault @GuttingDefault 445px;
		height: 18px;

		a {
			margin-right: 10px;
		}
	}
}

.itemContent {
	font-size: 13px;
}

.emptyContainer {
	font-size: 20px;
	color: @PrimaryLayoutingColor;
	display: block;
	margin: 195px auto 0 auto;
}

div.important h2 .title {
	color: @SecondaryCiColor;
}

.cols3_fckeditor {
	width: @ColsInput3;
}

#infoPageControls,
.ym-col3 {
	width: 890px;
	border: 0 ! important;

	&.fullWidth {
		width: 100%;
	}
}

div.containerGroup.alreadyRead .itemContent {
	color: @PrimaryLayoutingColor;
}

.containerTypeImage {
	height: 100%;
	width: 300px;
	float: left;

	div {
		color: @PrimaryFontColor;
		text-align: center;
		margin-top: 60px;
		font-size: 32px;
	}

	svg {
		fill: @SecondaryCiColor;
		height: 185px;
		position: relative;
		left: 20px;
		top: 40px;
	}
}

.containerItems {
	height: 100%;
}

#infoPageControls {
	margin: 30px 0 15px;
	padding: 0;
	width: 100%;
	background-color: @PrimaryBackgroundColor;

	.boxRow {
		display: flex;
		justify-content: space-between;
	}

	.outerBox {
		display: inline-block;
		margin: 0 27px 0 0;
		position: relative;
		height: 120px;
		border: 1px solid @SecondaryLayoutingColor;

		&.last {
			margin-right: 0;
		}

		.box {
			margin: 0;
			padding: 0;
			border-radius: 0;
			height: 100%;

			svg {
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				max-width: 80%;
				max-height: 80%;
			}

			&.metroBox {
				height: 100%;
				width: 100%;
				background: @HomepageButton;
				cursor: pointer;

				&:hover {
					background: @HomepageButtonHover;

					svg {
						fill: @HomepageButtonIconHoverColor;
					}
				}

				svg {
					fill: @HomepageButtonIconColor;
				}

				&.active {

					&,
					&:hover {
						cursor: default;
						background: @HomepageButtonActive;

						svg {
							fill: @HomepageButtonIconActiveColor;
						}
					}
				}

				.itemCount {
					background: none repeat scroll 0 0 @HomepageButtonItemCount;
					color: @HomepageButtonItemCountColor;
					font-size: 15px;
					font-weight: bold;
					z-index: @Index_InfopageItemCount;
					margin: 0;
					padding: 3px @GuttingDefault;
					width: (20px + (2 * @GuttingDefault));
					position: absolute;
					top: 10px;
					right: 30px;
					text-align: center;
					border-radius: 15px;
					border-bottom-left-radius: 0;
				}
			}
		}
	}
}